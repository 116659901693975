import ManageAdminData from '../../components/DataManagementModals/ManageAdminData';
import { useData } from '../../context/DataContext';
import { useStyle } from '../../context/StyleContext';

const UpdateAdminPassword = () => {
  const { isFetchingData } = useData();
  const { loader } = useStyle();
  return (
    <>
      {isFetchingData ? (
        loader
      ) : (
        <ManageAdminData isPasswordChangeRequired={true} />
      )}
    </>
  );
};

export default UpdateAdminPassword;

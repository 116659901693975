import ConfirmDialog from '../ConfirmDialog';
import Modal from 'react-bootstrap/Modal';
import { AiFillEdit } from 'react-icons/ai';
import { useState, useRef, useEffect } from 'react';
import { useStyle } from '../../context/StyleContext';
import 'animate.css';

const DisplayModalTemplate = ({
  title,
  data,
  CRUD,
  displayDataShow,
  toggleDisplayDataShow,
  DisplayModalComponent,
  EditModalComponent,
}) => {
  const { modalHeaderStyle, modalFooterStyle, primaryBtn, deleteBtn } =
    useStyle();
  const [isLoading, setIsLoading] = useState(false);
  const [editDataShow, setEditDataShow] = useState(false);

  //  Display Modal hide related

  const handleDisplayModalHide = () => {
    if (showConfirmation) {
      setShowConfirmation(false);
    }
    toggleDisplayDataShow();
  };

  //Remove button and processin related

  const handleRemove = () => {
    setIsLoading(true);

    setTimeout(async () => {
      const isDone = await CRUD.deleteData(data._id);
      if (isDone) {
        handleDisplayModalHide();
        setIsLoading(false);
      }
    }, 1000);
  };

  // Delete confirmation focus related
  const [showConfirmation, setShowConfirmation] = useState(false);
  const confirmDialogRef = useRef(null);

  useEffect(() => {
    if (showConfirmation && confirmDialogRef.current) {
      confirmDialogRef.current.focus();
    }
  }, [showConfirmation]);

  // Edit button and processing related

  const handleEdit = () => {
    handleDisplayModalHide();
    toggleEditDataShow();
  };

  const toggleEditDataShow = () => setEditDataShow(!editDataShow);
  const handleEditSubmit = async (values) => {
    const isDone = await CRUD.updateData(values, values._id);
    toggleEditDataShow();
    return isDone;
  };

  return (
    <>
      {editDataShow && EditModalComponent && (
        <EditModalComponent
          CRUD={CRUD}
          title={'Edit ' + title}
          data={data}
          show={editDataShow}
          handleShow={toggleEditDataShow}
          handleSubmit={handleEditSubmit}
          isLoading={isLoading}
          setIsLoading={setIsLoading}
        />
      )}
      <Modal
        size='lg'
        aria-labelledby='contained-modal-title-vcenter'
        centered
        show={displayDataShow}
        onHide={handleDisplayModalHide}
        backdrop='static'
        keyboard={false}
        className='.bg-black .bg-gradient'
        style={{ backdropFilter: 'blur(1.5px)' }}
      >
        <Modal.Header closeButton style={modalHeaderStyle}>
          <Modal.Title className='text-capitalize fw-bolder text-secondary-emphasis'>
            <span style={{ color: 'var(--primaryColor)' }}>
              {data.title !== undefined ? data.title : title}
            </span>
          </Modal.Title>
        </Modal.Header>

        <DisplayModalComponent
          CRUD={CRUD}
          data={data}
          handleShow={handleDisplayModalHide}
        />

        <Modal.Footer style={modalFooterStyle}>
          {EditModalComponent &&
            deleteBtn(
              () => setShowConfirmation(true),
              isLoading,
              `Delete this ${title.slice(0, title.length - 1)}`
            )}
          {EditModalComponent &&
            primaryBtn(
              <AiFillEdit size={20} />,
              handleEdit,
              false,
              `Edit this ${title.slice(0, title.length - 1)}`
            )}

          {showConfirmation && (
            <div className='w-100 mt-3 text-center animate__animated animate__fadeIn '>
              <ConfirmDialog
                message={`Are you sure you want to delete "${
                  data.title
                    ? data.title
                    : data.name
                    ? data.name
                    : data.truckPlateNumber
                    ? data.truckPlateNumber
                    : data.tripId
                }"?`}
                onConfirm={handleRemove}
                onCancel={() => setShowConfirmation(false)}
                reference={confirmDialogRef}
              />
            </div>
          )}
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default DisplayModalTemplate;

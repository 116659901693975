import { useEffect, useState } from "react";

export const useScreenSize = () => {
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      const screenWidth = window.innerWidth;
      setIsMobile(screenWidth < 768);
    };

    handleResize();

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return isMobile;
};

export const useScreenTop = () => {
  const [isAtTop, setIsAtTop] = useState(true);

  useEffect(() => {
    // Function to check if the screen is at the top
    function isAtTopOfPage() {
      const scrollY = window.scrollY || window.pageYOffset;
      const threshold = 0;
      return scrollY <= threshold;
    }

    // Event listener for the scroll event
    function handleScroll() {
      const atTop = isAtTopOfPage();
      setIsAtTop(atTop);
    }

    // Add the scroll event listener when the component mounts
    window.addEventListener("scroll", handleScroll);

    // Remove the event listener when the component unmounts
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return isAtTop;
};

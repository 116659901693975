import TableTemplate from '../utils/TableTemplate/TableTemplate';
import TrucksTable from '../components/Tables/TrucksTable';
import TruckDisplayModal from '../components/DisplayModals/TruckDisplayModal';
import ManageTruckData from '../components/DataManagementModals/ManageTruckData';
import { BsTruckFront } from 'react-icons/bs';
import { MdConfirmationNumber } from 'react-icons/md';
import { useState, useEffect } from 'react';
import { useData } from '../context/DataContext';
import { useStyle } from '../context/StyleContext';
import { useScreenSize } from '../utils/ScreenSizeDetector';

const Trucks = () => {
  const { partition, showTitle, textSearch, loader, addBtn } = useStyle();
  const isMobile = useScreenSize();

  const { trucks, postTruck, deleteTruck, updateTruck, isFetchingData } =
    useData();

  const CRUD = {
    //storing these functions in a CRUD object in order to use them in the generic template...
    postData: (data) => postTruck(data),
    updateData: (data) => updateTruck(data, data._id),
    deleteData: (id) => deleteTruck(id),
  };

  const headerData = [
    {
      title: 'Truck Picture',
      width: 17,
    },
    {
      title: 'Plate Number',
      width: 25,
    },
    {
      title: 'Model',
      width: 25,
    },
    {
      title: 'Registered Year',
      width: 17,
    },
    {
      title: 'Action',
      width: 16,
    },
  ];

  //Related to handling search by title, email and approval status processing
  const [isLoading, setIsLoading] = useState(false);
  const [show, setShow] = useState(false);
  const handleShow = () => setShow(!show);

  const [searchPlateNumber, setSearchPlateNumber] = useState('');
  const handleSearchPlateNumberChange = (e) =>
    setSearchPlateNumber(e.target.value);

  const [searchModel, setSearchModel] = useState('');
  const handleSearchModelChange = (e) => setSearchModel(e.target.value);

  const [filteredTrucks, setFilteredTrucks] = useState(trucks);

  useEffect(() => {
    const filterData = () => {
      if (trucks !== undefined) {
        const filtered = trucks.filter((singleTruck) => {
          const { truckPlateNumber, truckModel, isDeleted } = singleTruck;
          const lowerCasePlateNumber = truckPlateNumber
            ? truckPlateNumber.toLowerCase()
            : '';
          const lowerCaseModel = truckModel ? truckModel.toLowerCase() : '';

          return (
            (searchPlateNumber === '' ||
              (lowerCasePlateNumber &&
                lowerCasePlateNumber.includes(
                  searchPlateNumber.toLowerCase()
                ))) &&
            (searchModel === '' ||
              (lowerCaseModel &&
                lowerCaseModel.includes(searchModel.toLowerCase()))) &&
            !isDeleted
          );
        });
        setFilteredTrucks(filtered);
      }
    };

    filterData();
  }, [trucks, searchPlateNumber, searchModel]);

  return (
    <>
      {isFetchingData ? (
        // Loading if the data isn't fetched yet
        loader
      ) : (
        <div className='my-3 mx-4 mx-md-2 mx-lg-2'>
          <ManageTruckData
            title='Add new Truck'
            show={show}
            handleShow={handleShow}
            handleSubmit={CRUD.postData}
            isLoading={isLoading}
            setIsLoading={setIsLoading}
          />

          {showTitle('Trucks')}

          {partition}

          <div className='w-100 pe-4 d-flex flex-md-row flex-column justify-content-between'>
            <div className='d-flex flex-md-row flex-column'>
              <div className=' mb-md-0 mb-2 d-flex justify-content-between'>
                {textSearch(
                  <MdConfirmationNumber size={18} className='me-2 mt-1' />,
                  searchPlateNumber,
                  'Search Plate Number',
                  handleSearchPlateNumberChange
                )}
                {isMobile && (
                  <span className='ms-2'>
                    {addBtn(handleShow, 'Add new Truck')}
                  </span>
                )}
              </div>

              <div className='mx-md-1 mb-md-0 mb-2 d-flex'>
                {textSearch(
                  <BsTruckFront size={18} className='me-2 mt-1' />,
                  searchModel,
                  'Search By Model',
                  handleSearchModelChange
                )}
              </div>
            </div>

            {!isMobile && addBtn(handleShow, 'Add new Truck')}
          </div>

          {partition}

          {trucks.length === 0 ? (
            <h4 style={{ color: 'var(--partitionColor)', opacity: '70%' }}>
              No Trucks to display...
            </h4>
          ) : (
            <TableTemplate
              pageTitle={'Trucks'}
              headerData={headerData}
              tableData={filteredTrucks}
              CRUD={CRUD}
              TableComponent={TrucksTable}
              DisplayModalComponent={TruckDisplayModal}
              EditModalComponent={ManageTruckData}
            />
          )}
        </div>
      )}
    </>
  );
};

export default Trucks;

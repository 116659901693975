import DisplayModalTemplate from '../../../utils/DisplayModalTemplate/DisplayModalTemplate';

import { Button, Accordion, Modal } from 'react-bootstrap';
import { useState } from 'react';
import { useStyle } from '../../../context/StyleContext';
import ManageDeduction from '../../DataManagementModals/Logs/ManageDeduction';

const ExpenseDeductionDisplay = ({
  data,
  isExpense = false,
  handleClick,
  currentAdminLog,
}) => {
  const { addBtn } = useStyle();

  const [isLoading, setIsLoading] = useState(false);

  const [selectedItem, setSelectedItem] = useState(null);

  const [showDeductionModal, setShowDeductionModal] = useState(false);
  const toggleShowDeductionModal = () =>
    setShowDeductionModal(!showDeductionModal);

  const [showReceipt, setShowReceipt] = useState(false);
  const toggleShowReceipt = () => setShowReceipt(!showReceipt);

  return (
    <>
      {selectedItem && showReceipt && selectedItem.receiptPictureURL && (
        <DisplayModalTemplate
          title={`${selectedItem.title} receipt`}
          data={selectedItem.receiptPictureURL}
          displayDataShow={showReceipt}
          toggleDisplayDataShow={toggleShowReceipt}
          DisplayModalComponent={ReceiptDisplayModal}
          EditModalComponent={undefined}
        />
      )}

      {showDeductionModal && (
        <ManageDeduction
          title='Add new Deduction'
          show={showDeductionModal}
          handleShow={toggleShowDeductionModal}
          handleSubmit={handleClick.addNewDeduction}
          isLoading={isLoading}
          setIsLoading={setIsLoading}
        />
      )}
      <div className='pe-2 mb-3'>
        <div className='pb-2 d-flex justify-content-between align-items-center'>
          {' '}
          <h4 className='text-start' style={{ color: 'var(--primaryColor)' }}>
            {isExpense ? 'Expenses' : 'Deductions'}:{' '}
          </h4>
          {!isExpense &&
            !currentAdminLog &&
            addBtn(toggleShowDeductionModal, 'Add new Deduction')}
        </div>
        {data.length === 0 ? (
          <h4
            className=''
            style={{ color: 'var(--partitionColor)', opacity: '70%' }}
          >
            No {isExpense ? 'expense' : 'deduction'} to display...
          </h4>
        ) : (
          <Accordion>
            {data.map((item, index) => {
              return (
                <Accordion.Item key={index} eventKey={index}>
                  <Accordion.Header className='col-12'>
                    <h5
                      style={{ color: 'var(--headingColor)' }}
                      className='text-capitalize'
                    >
                      {item.title}{' '}
                      {item.isDenied && (
                        <span className='ms-2 text-danger'> Rejected</span>
                      )}
                    </h5>
                  </Accordion.Header>
                  <Accordion.Body>
                    <p style={{ color: 'var(--basicTextColor)' }}>
                      {item.description}
                    </p>

                    <h6>
                      Cost:{' '}
                      <span style={{ color: 'var(--partitionColor)' }}>
                        ${item.cost}
                      </span>
                    </h6>

                    <div className=''>
                      {isExpense && item.receiptPictureURL && (
                        <Button
                          variant='outline-primary'
                          className='me-2 mb-2 mb-md-0'
                          onClick={() => {
                            setSelectedItem(item);
                            toggleShowReceipt();
                          }}
                        >
                          Show Receipt
                        </Button>
                      )}
                      {!currentAdminLog && (
                        <Button
                          variant={
                            !isExpense || !item.isDenied
                              ? 'outline-danger'
                              : 'outline-success'
                          }
                          className='me-md-2 mb-2 mb-md-0'
                          onClick={() =>
                            isExpense
                              ? handleClick(!item.isDenied, item._id)
                              : handleClick.removeExistingDeduction(
                                  item._id,
                                  item.cost
                                )
                          }
                        >
                          {!isExpense
                            ? 'Delete'
                            : item.isDenied
                            ? 'Accept'
                            : 'Reject'}
                        </Button>
                      )}
                    </div>
                  </Accordion.Body>
                </Accordion.Item>
              );
            })}
          </Accordion>
        )}
      </div>
    </>
  );
};

export default ExpenseDeductionDisplay;

const ReceiptDisplayModal = ({ data }) => {
  const { modalHeaderStyle } = useStyle();

  return (
    <Modal.Body
      style={{ ...modalHeaderStyle, maxHeight: '80vh' }}
      className='overflow-auto'
    >
      <div className='row g-0'>
        <img src={data} alt='Receipt Image' />
      </div>
    </Modal.Body>
  );
};

import logo from '../assets/images/logo.png';
import classes from './Login.module.css';
import * as Yup from 'yup';
import { useState } from 'react';
import { useFormik } from 'formik';
import { useUser } from '../context/UserContext';
import { Form, Button } from 'react-bootstrap';

const Login = () => {
  const { login } = useUser();

  const [isLoading, setIsLoading] = useState(false);

  const formik = useFormik({
    initialValues: { email: '', password: '' },
    validationSchema: Yup.object({
      email: Yup.string()
        .email('Invalid email address')
        .required('Email is required'),
      password: Yup.string()
        .required('Password is required')
        .min(8, 'Password must be at least 8 characters'),
    }),
    onSubmit: async (values) => {
      setIsLoading(true);
      setTimeout(() => {
        login(values);
        setIsLoading(false);
      }, 1000);
    },
  });

  const loginEntryField = (
    fieldName,
    label,
    type,
    value,
    touchStatus,
    error
  ) => {
    return (
      <Form.Group className="mb-4 w-75" controlId={fieldName}>
        <Form.Label className="w-100 text-start">
          {label} <span style={{ color: 'red' }}>*</span>
        </Form.Label>
        <Form.Control
          style={{
            borderColor: 'var(--primaryColor)',
          }}
          type={type}
          value={value}
          onChange={formik.handleChange}
        />
        {error && touchStatus && (
          <div className="w-100 ms-1 mt-1 text-start text-danger">{error}</div>
        )}
      </Form.Group>
    );
  };

  return (
    <>
      <div
        className={`d-flex align-items-center justify-content-center ${classes.backgroundPicture}`}
      >
        <div
          className={` d-flex align-items-center justify-content-center ${classes.container}`}
        >
          {/* {!isMobile && (
              <img src={truckLogin} style={{ height: "70vh", width: "40%" }} />
            )} */}
          <Form
            className={` d-flex flex-column justify-content-center align-items-center ${classes.form}`}
            onSubmit={formik.handleSubmit}
          >
            <div className="mb-2 d-flex align-items-center ">
              <img src={logo} style={{ maxWidth: '80px' }} />
              <span className="ms-3 fs-3 fw-semibold ">Drivers Log</span>
            </div>

            {loginEntryField(
              'email',
              'Email',
              'email',
              formik.values.email,
              formik.touched.email,
              formik.errors.email
            )}
            {loginEntryField(
              'password',
              'Password',
              'password',
              formik.values.password,
              formik.touched.password,
              formik.errors.password
            )}

            <Button
              variant="primary"
              className="w-50 mt-2"
              type="submit"
              disabled={isLoading}
            >
              {isLoading ? (
                <>
                  <span
                    className="spinner-border spinner-border-sm"
                    role="status"
                    aria-hidden="true"
                  >
                    {' '}
                  </span>
                  <span> Signing in</span>
                </>
              ) : (
                <>Login</>
              )}
            </Button>
          </Form>
        </div>
      </div>
    </>
  );
};

export default Login;

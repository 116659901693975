import TableTemplate from '../utils/TableTemplate/TableTemplate';
import DriversTable from '../components/Tables/DriversTable';
import DriverDisplayModal from '../components/DisplayModals/DriverDisplayModal';
import ManageDriverData from '../components/DataManagementModals/ManageDriverData';
import { MdOutlineAlternateEmail } from 'react-icons/md';
import { AiOutlineUser } from 'react-icons/ai';
import { useState, useEffect } from 'react';
import { useData } from '../context/DataContext';
import { useStyle } from '../context/StyleContext';
import { useScreenSize } from '../utils/ScreenSizeDetector';

const Drivers = () => {
  const { partition, showTitle, textSearch, loader, addBtn } = useStyle();
  const isMobile = useScreenSize();

  const { drivers, postDriver, deleteDriver, updateDriver, isFetchingData } =
    useData();

  const CRUD = {
    //storing these functions in a CRUD object in order to use them in the generic template...
    postData: (data) => postDriver(data),
    updateData: (data) => updateDriver(data, data._id),
    deleteData: (id) => deleteDriver(id),
  };

  const headerData = [
    { title: 'Ready', width: 5 },
    {
      title: 'Profile Picture',
      width: 17,
    },
    {
      title: 'Name',
      width: 25,
    },
    {
      title: 'Email',
      width: 25,
    },
    {
      title: 'Number',
      width: 17,
    },
    {
      title: 'Action',
      width: 11,
    },
  ];

  //Related to handling search by title, email and approval status processing
  const [isLoading, setIsLoading] = useState(false);
  const [show, setShow] = useState(false);
  const handleShow = () => setShow(!show);

  const [searchName, setSearchName] = useState('');
  const handleSearchNameChange = (e) => setSearchName(e.target.value);

  const [searchEmail, setSearchEmail] = useState('');
  const handleSearchEmailChange = (e) => setSearchEmail(e.target.value);

  const [filteredDrivers, setFilteredDrivers] = useState(drivers);

  useEffect(() => {
    const filterData = () => {
      if (drivers !== undefined) {
        const filtered = drivers.filter((singleDriver) => {
          const { name, email, isDeleted } = singleDriver;
          const lowerCaseName = name ? name.toLowerCase() : '';
          const lowerCaseEmail = email ? email.toLowerCase() : '';

          return (
            (searchName === '' ||
              (lowerCaseName &&
                lowerCaseName.includes(searchName.toLowerCase()))) &&
            (searchEmail === '' ||
              (lowerCaseEmail &&
                lowerCaseEmail.includes(searchEmail.toLowerCase()))) &&
            !isDeleted
          );
        });
        setFilteredDrivers(filtered);
      }
    };

    filterData();
  }, [drivers, searchName, searchEmail]);

  return (
    <>
      {isFetchingData ? (
        // Loading if the data isn't fetched yet
        loader
      ) : (
        <div className='my-3 mx-4 mx-md-2 mx-lg-2'>
          <ManageDriverData
            title='Add new Driver'
            show={show}
            handleShow={handleShow}
            handleSubmit={CRUD.postData}
            isLoading={isLoading}
            setIsLoading={setIsLoading}
          />

          {showTitle('Drivers')}

          {partition}

          <div className='w-100 pe-4 d-flex flex-md-row flex-column justify-content-between'>
            <div className='d-flex flex-md-row flex-column'>
              <div className=' mb-md-0 mb-2 d-flex justify-content-between'>
                {textSearch(
                  <AiOutlineUser size={18} className='me-2 mt-1' />,
                  searchName,
                  'Search By Name',
                  handleSearchNameChange
                )}
                {isMobile && (
                  <span className='ms-2'>
                    {addBtn(handleShow, 'Add new Driver')}
                  </span>
                )}
              </div>

              <div className='mx-md-1 mb-md-0 mb-2 d-flex'>
                {textSearch(
                  <MdOutlineAlternateEmail size={18} className='me-2 mt-1' />,
                  searchEmail,
                  'Search By Email',
                  handleSearchEmailChange
                )}
              </div>
            </div>

            {!isMobile && addBtn(handleShow, 'Add new Driver')}
          </div>

          {partition}

          {drivers.length === 0 ? (
            <h4 style={{ color: 'var(--partitionColor)', opacity: '70%' }}>
              No Drivers to display...
            </h4>
          ) : (
            <TableTemplate
              pageTitle={'Drivers'}
              headerData={headerData}
              tableData={filteredDrivers}
              CRUD={CRUD}
              TableComponent={DriversTable}
              DisplayModalComponent={DriverDisplayModal}
              EditModalComponent={ManageDriverData}
            />
          )}
        </div>
      )}
    </>
  );
};

export default Drivers;

import ManipulateDataTemplate from '../../utils/DataManipulationTemplate/ManipulateDataTemplate';
import * as Yup from 'yup';
import { useNavigate } from 'react-router-dom';
import { useFormik } from 'formik';
import { useStyle } from '../../context/StyleContext';
import { useUser } from '../../context/UserContext';
import { useState } from 'react';

const ManageAdminData = ({ isPasswordChangeRequired }) => {
  const navigate = useNavigate();
  const { textEntryField, pictureEntryField } = useStyle();
  const { user, updateAdmin, updateAdminPassword } = useUser();

  const [isLoading, setIsLoading] = useState(false);
  const [show, setShow] = useState(true);
  const toggleShow = () => setShow(!show);

  const handleSubmit = (data) => {
    if (isPasswordChangeRequired) return updateAdminPassword(data, data._id);
    else {
      const formDataToSend = new FormData();
      formDataToSend.append('name', data.name);
      formDataToSend.append('address', data.address);
      formDataToSend.append('contactNumber', data.contactNumber);
      formDataToSend.append('file', data.file);

      return updateAdmin(formDataToSend, data._id);
    }
  };

  const validationSchema = isPasswordChangeRequired
    ? Yup.object().shape({
        newPassword: Yup.string()
          .required('New Password is required')
          .min(8, 'Password must be at least 8 characters long'),
        oldPassword: Yup.string()
          .required('Old Password is required')
          .min(8, 'Password must be at least 8 characters long'),
      })
    : Yup.object().shape({
        name: Yup.string().required('Name is required'),
        contactNumber: Yup.string().required('Number is required'),
        address: Yup.string().required('Address is required'),
      });

  let formikInitializer = isPasswordChangeRequired
    ? { ...user, newPassword: '', oldPassword: '' }
    : { ...user, file: null };

  const formik = useFormik({
    initialValues: formikInitializer,
    validationSchema,
    onSubmit: (values, { resetForm }) => {
      setIsLoading(true);

      setTimeout(async () => {
        const isDone = await handleSubmit(values);
        if (isDone) {
          formikInitializer.file = null;
          resetForm(formikInitializer);

          navigate('/admin');
          toggleShow();
          setIsLoading(false);
        }
      }, 1000);
    },
  });

  return (
    <>
      <ManipulateDataTemplate
        title={'Update Admin Details'}
        show={show}
        handleShow={toggleShow}
        formik={formik}
        isLoading={isLoading}
        navigate={() => navigate('/admin')}
      >
        {isPasswordChangeRequired ? (
          <>
            {textEntryField(
              'oldPassword',
              'Old Password',
              'password',
              formik,
              true,
              formik.values.oldPassword,
              formik.touched.oldPassword,
              formik.errors.oldPassword
            )}
            {textEntryField(
              'newPassword',
              'New Password',
              'password',
              formik,
              true,
              formik.values.newPassword,
              formik.touched.newPassword,
              formik.errors.newPassword
            )}
          </>
        ) : (
          <div>
            {textEntryField(
              'name',
              'Name',
              'text',
              formik,
              true,
              formik.values.name,
              formik.touched.name,
              formik.errors.name
            )}
            {textEntryField(
              'contactNumber',
              'Number',
              'text',
              formik,
              true,
              formik.values.contactNumber,
              formik.touched.contactNumber,
              formik.errors.contactNumber
            )}
            {textEntryField(
              'address',
              'Address',
              'text',
              formik,
              true,
              formik.values.address,
              formik.touched.address,
              formik.errors.address
            )}

            {pictureEntryField(
              ['profilePictureURL', 'file'],
              'Admin Profile Picture',
              'file',
              formik,
              false,
              undefined,
              undefined
            )}
          </div>
        )}
      </ManipulateDataTemplate>
    </>
  );
};

export default ManageAdminData;

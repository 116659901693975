import Modal from 'react-bootstrap/Modal';
import { useScreenSize } from '../../utils/ScreenSizeDetector';
import { useStyle } from '../../context/StyleContext';

const TruckDisplayModal = ({ data }) => {
  const isMobile = useScreenSize();
  const { modalHeaderStyle, showTextFieldValueInDisplay } = useStyle();

  return (
    <Modal.Body
      style={{ ...modalHeaderStyle, maxHeight: '80vh' }}
      className='overflow-auto'
    >
      <div className='row g-0'>
        <div className='col-lg-4 d-flex flex-column align-items-center justify-content-center'>
          <img
            src={data.truckPictureURL}
            style={{
              maxWidth: isMobile ? '100%' : '90%',
              height: 'auto',
              display: 'block',
              margin: isMobile ? '10px auto' : '0 auto',
              borderRadius: '10px',
            }}
          />
        </div>

        <div
          className={`ms-md-4 mt-lg-0 mt-2 col-lg-7 text-wrap text-start text-break overflow-auto`}
        >
          {showTextFieldValueInDisplay(
            'Truck Plate Number',
            data.truckPlateNumber
          )}

          {showTextFieldValueInDisplay('Truck Model', data.truckModel)}

          {showTextFieldValueInDisplay(
            'Registration Year',
            data.registeredYear
          )}
        </div>
      </div>
    </Modal.Body>
  );
};

export default TruckDisplayModal;

import classes from './Sidebar.module.css';
import { NavLink, useNavigate } from 'react-router-dom';
import { Collapse } from 'react-bootstrap';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars } from '@fortawesome/free-solid-svg-icons';
import { FaTruck } from 'react-icons/fa';
import { HiUsers } from 'react-icons/hi';
import { IoClose, IoDocumentsOutline } from 'react-icons/io5';
import { MdLogout, MdOutlinePassword } from 'react-icons/md';
import {
  RiAdminFill,
  RiArrowRightSFill,
  RiArrowDownSFill,
} from 'react-icons/ri';
import { TbListDetails } from 'react-icons/tb';

import { useState } from 'react';
import { useUser } from '../../context/UserContext';
import { useScreenSize, useScreenTop } from '../../utils/ScreenSizeDetector';
import 'animate.css';

const Sidebar = () => {
  const { logout } = useUser();
  const navigate = useNavigate();

  //For checking if the screen is of mobile size and is it at the top or not
  const isMobile = useScreenSize();
  const isAtTop = useScreenTop();

  // For sidebar in mobile mode
  const [isOpen, setIsOpen] = useState(false);
  const [isAdminOpen, setIsAdminOpen] = useState(false);

  const toggleSidebar = () => {
    setIsOpen(!isOpen);
  };

  const controlCollapses = () => {
    setIsOpen(false);
    setIsAdminOpen(false);
  };

  const linkComponent = (text, icon) => {
    return (
      <li className="nav-item">
        <NavLink
          className={({ isActive }) => {
            return isActive ? 'nav-link ' + classes.active : 'nav-link';
          }}
          onClick={controlCollapses}
          to={`/${text}`}
        >
          {icon}
          <span className="text-uppercase">{text}</span>
        </NavLink>
      </li>
    );
  };

  return (
    <nav
      className={`mb-5 ${
        !isMobile
          ? ''
          : isAtTop !== true && `${classes.sidebarMobile} pt-5 pb-2 fixed-top `
      }`}
    >
      <div
        id="sidebarMenu"
        className={`${classes.sidebar} ${
          isMobile ? classes.sidebarMobile : ''
        } ${isOpen ? classes.open : ''}`}
      >
        {isMobile && (
          <div className="position-absolute">
            <button
              className={classes.sandwichButton}
              // style={{ color: "var(--secondaryColor)" }}
              onClick={toggleSidebar}
              aria-label={isOpen ? 'Close Sidebar' : 'Open Sidebar'}
            >
              {isOpen ? (
                <IoClose className={classes.sandwichButton} />
              ) : (
                <FontAwesomeIcon
                  icon={faBars}
                  className={classes.sandwichButton}
                />
              )}
            </button>
          </div>
        )}
        <div className="position-sticky pt-3">
          <div>
            <h2 className={classes.logo}>Admin Panel</h2>
          </div>

          <ul className="nav flex-column">
            {linkComponent('drivers', <HiUsers size={22} className={'me-2'} />)}

            {linkComponent('trucks', <FaTruck size={22} className={'me-2'} />)}

            {linkComponent(
              'drivers-logs',
              <IoDocumentsOutline size={22} className={'me-2'} />
            )}

            <li className="nav-item">
              <NavLink
                className={({ isActive }) => {
                  return isActive ? 'nav-link ' + classes.active : 'nav-link';
                }}
                aria-controls="collapse-links"
                aria-expanded={isAdminOpen}
                onClick={() => setIsAdminOpen(!isAdminOpen)}
                to={'/admin'}
              >
                <RiAdminFill size={22} className={'me-2'} />
                ADMIN
                {isAdminOpen ? (
                  <RiArrowDownSFill size={25} className={'mb-1 '} />
                ) : (
                  <RiArrowRightSFill size={25} className={'mb-1 '} />
                )}
              </NavLink>

              <Collapse in={isAdminOpen}>
                <div id="collapse-links">
                  <NavLink
                    className={({ isActive }) => {
                      return isActive
                        ? 'nav-link ' + classes.active
                        : 'nav-link';
                    }}
                    onClick={() => setIsOpen(false)}
                    to={'/admin/update-details'}
                  >
                    <TbListDetails size={20} className={'me-2'} />
                    UPDATE DETAILS
                  </NavLink>

                  <NavLink
                    className={({ isActive }) => {
                      return isActive
                        ? 'nav-link ' + classes.active
                        : 'nav-link';
                    }}
                    onClick={() => setIsOpen(false)}
                    to={'/admin/update-password'}
                  >
                    <MdOutlinePassword size={20} className={'me-2'} />
                    UPDATE PASSWORD
                  </NavLink>
                </div>
              </Collapse>
            </li>

            <li className="nav-item">
              <div
                className={`nav-link ${classes.logout}`}
                onClick={() => {
                  logout().then(() => navigate('/login'));
                }}
              >
                <MdLogout size={20} className={'me-2'} />
                <span style={{ cursor: 'pointer' }}>Logout</span>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
};

export default Sidebar;

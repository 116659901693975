import { useState } from 'react';
import { useStyle } from '../../context/StyleContext';

const TrucksTable = ({ headerData, currentItems, handleClick, classes }) => {
  // This component is made to display the data of trucks in a tabular form...

  const [current, setCurrent] = useState(-1);
  const { tableBtn } = useStyle();

  const tableColumn = (content, index) => {
    return (
      <td className={`bg-transparent ${classes.column}`}>
        <p
          className={`${
            index == current ? classes.contentLight : classes.content
          } text-center`}
        >
          {content}
        </p>
      </td>
    );
  };

  return (
    <>
      <thead>
        {/* Displaying the header data i.e, top row of the table */}
        <tr className={`${classes.headRow}`}>
          {headerData.map((item, index) => {
            return (
              <th
                className={`pb-3 bg-transparent text-start border-bottom-0`}
                key={index}
              >
                <h6 style={{ padding: '0px 3px' }} className="text-center">
                  {item.title}
                </h6>
              </th>
            );
          })}
        </tr>
      </thead>

      <tbody>
        {/* Displaying the data of trucks where column 1,2,3,4,5 contain truck pic, plate number, model, registration year and action button */}
        {currentItems.map((item, index) => {
          return (
            <tr
              className={`${classes.row}`}
              key={index}
              onClick={() => handleClick(item)}
              onMouseEnter={() => setCurrent(index)}
              onMouseLeave={() => setCurrent(-1)}
            >
              <td
                className={`bg-transparent text-center rounded-start-4 ${classes.column}`}
              >
                <img
                  src={item.truckPictureURL}
                  alt="Truck Image"
                  className={classes.tileImage}
                />
              </td>

              {tableColumn(item.truckPlateNumber, index)}
              {tableColumn(item.truckModel, index)}
              {tableColumn(item.registeredYear, index)}

              <td className={`bg-transparent rounded-end-4 border-bottom-0`}>
                {tableBtn(
                  () => handleClick(item),
                  'Show Truck',
                  current === index
                )}
              </td>
            </tr>
          );
        })}
      </tbody>
    </>
  );
};

export default TrucksTable;

import React, { useContext } from 'react';
import classes from './StyleContext.module.css';
import Delete from '../assets/images/delete.gif';
import loading from '../assets/images/loading.gif';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import { Collapse } from 'react-bootstrap';
import { Form, Button } from 'react-bootstrap';
import {
  AiFillDelete,
  AiOutlineArrowRight,
  AiOutlineArrowLeft,
} from 'react-icons/ai';
import { MdOutlineAdd } from 'react-icons/md';

const StyleContext = React.createContext();

export const useStyle = () => {
  return useContext(StyleContext);
};

const StyleProvider = ({ children }) => {
  //----------------------------Style---------------------------------------

  const darkThemeStyle = {
    background: '#232023',
    color: 'silver',
    border: 'var(--primaryColor) 1px solid',
  };

  // Search related styles

  const searchDivStyle = {
    color: 'silver',
    border: 'var(--primaryColor) 1.5px solid',
    borderRadius: '20px',
  };

  const searchInputStyle = {
    padding: '8px 0px',
    background: 'transparent',
    color: 'black',
    border: '0px',
    outline: 'none',
  };

  // Modal related styles

  const modalHeaderStyle = {
    background: 'var(--extraColor)',
    borderBottom: 'var(--primaryColor) solid 1.5px',
  };

  const modalFooterStyle = {
    background: 'var(--extraColor)',
    borderTop: '0px',
  };

  // Button related styles

  const paginationBtnStyle = {
    border: '0px',
    color: 'var(--primaryColor)',
    borderRadius: '50%',
    height: '35px',
    margin: '0px 2px',
  };

  const primaryBtnStyle = {
    color: 'var(--primaryColor)',
    borderColor: 'var(--primaryColor)',
  };

  const backButtonStyle = {
    color: 'var(--primaryColor)',
    background: 'transparent',
    border: '0px',
  };

  //----------------------------Small components ---------------------------------------

  const partition = (
    <hr
      style={{
        color: 'var(--partitionColor) !important',
        borderTop: '1.5px solid var(--partitionColor) !important',
      }}
    />
  );

  // Display related components

  const showTitle = (text) => {
    return (
      <h2
        className='text-md-start text-center'
        style={{ color: 'var(--primaryColor)' }}
      >
        {text}
      </h2>
    );
  };

  const showTextFieldValueInDisplay = (fieldName, value) => {
    // this function is to display the data in a format of fieldName: value
    return (
      <h5 className={`mb-2 ${classes.justifyText}`}>
        <span style={{ color: 'var(--primaryColor)' }}>{fieldName}:</span>
        <span className='ms-2 fs-5 ' style={{ color: 'var(--basicTextColor)' }}>
          {value ? value : 'N/A'}
        </span>
      </h5>
    );
  };

  // Search Field component

  const textSearch = (icon, value, placeholder, handleChange) => {
    return (
      <div
        className='ps-3 pe-1 d-flex align-items-center'
        style={searchDivStyle}
      >
        {icon}
        <input
          className='w-100'
          type='text'
          value={value}
          style={searchInputStyle}
          placeholder={placeholder}
          onChange={handleChange}
        />
      </div>
    );
  };

  // Overlay container

  const overlayContainer = (overlay, content) => {
    return (
      <OverlayTrigger
        placement='top'
        delay={{ show: 100, hide: 400 }}
        overlay={
          <Tooltip id='button-tooltip-2'>
            <span
              style={{
                color: 'var(--primaryColor)',
                borderRadius: '8%',
                paddingLeft: '3px',
                paddingRight: '3px',
              }}
            >
              {overlay}
            </span>
          </Tooltip>
        }
      >
        {content}
      </OverlayTrigger>
    );
  };

  // Button components

  const primaryBtn = (text, handleClick, isLoading = false, overlay = '') => {
    return overlay !== '' ? (
      overlayContainer(
        overlay,
        <Button
          variant='outline-primary'
          className={`${classes.primaryBtn}`}
          onClick={handleClick}
          disabled={isLoading}
        >
          {text}
        </Button>
      )
    ) : (
      <Button
        variant='outline-primary'
        className={`${classes.primaryBtn}`}
        onClick={handleClick}
        disabled={isLoading}
      >
        {text}
      </Button>
    );
  };

  const submitBtn = (isLoading = false) => {
    return (
      <Button
        variant='outline-primary'
        type='submit'
        className={`${classes.primaryBtn}`}
        disabled={isLoading}
      >
        {isLoading ? (
          <>
            <span
              className='spinner-border spinner-border-sm'
              role='status'
              aria-hidden='true'
            >
              {' '}
            </span>
            <span> Submitting</span>
          </>
        ) : (
          <>Submit</>
        )}
      </Button>
    );
  };

  const addBtn = (handleClick, overlay = 'Add') => {
    return overlayContainer(
      overlay,
      <Button
        variant='outline-primary'
        className={`${classes.primaryBtn}`}
        onClick={handleClick}
      >
        <MdOutlineAdd size={25} />
      </Button>
    );
  };

  const deleteBtn = (handleClick, isLoading, overlay = 'Delete') => {
    return overlayContainer(
      overlay,
      <Button
        variant='outline-danger'
        className='mx-2'
        onClick={handleClick}
        disabled={isLoading}
      >
        {isLoading ? (
          <>
            <span
              className='spinner-border spinner-border-sm'
              role='status'
              aria-hidden='true'
            >
              {' '}
            </span>
            <span>
              {' '}
              <img src={Delete} style={{ width: '25px', height: '25px' }} />
            </span>
          </>
        ) : (
          <AiFillDelete size={20} />
        )}
      </Button>
    );
  };

  const tableBtn = (handleClick, overlay = 'Open', hover = false) => {
    return overlayContainer(
      overlay,
      <Button
        variant='outline-secondary'
        className={`align-self-center ${classes.btn} ${
          hover ? classes.btnWhite : ''
        }`}
        onClick={handleClick}
      >
        <AiOutlineArrowRight size={25} />
      </Button>
    );
  };

  const backBtn = (handleClick, overlay = 'Move Back') => {
    return (
      <OverlayTrigger
        placement='top'
        delay={{ show: 100, hide: 400 }}
        overlay={
          <Tooltip id='button-tooltip-2'>
            <span
              style={{
                color: 'var(--primaryColor)',
                borderRadius: '8%',
                paddingLeft: '3px',
                paddingRight: '3px',
              }}
            >
              {overlay}
            </span>
          </Tooltip>
        }
      >
        <button className={`${classes.btn}`} onClick={handleClick}>
          <AiOutlineArrowLeft size={20} />
        </button>
      </OverlayTrigger>
    );
  };

  // Entry field components

  const textEntryField = (
    fieldName,
    label,
    type,
    formik,
    isRequired,
    value,
    touchStatus,
    error
  ) => {
    return (
      <Form.Group className='mb-3 me-2' controlId={fieldName}>
        {type !== 'switch' && (
          <Form.Label>
            {label} {isRequired && <span style={{ color: 'red' }}>*</span>}
          </Form.Label>
        )}
        {type !== 'textarea' ? (
          type !== 'switch' ? (
            <Form.Control
              type={
                type === 'text'
                  ? 'text'
                  : type === 'password'
                  ? 'password'
                  : 'number'
              }
              step='0.01'
              name={fieldName}
              value={value}
              onChange={formik.handleChange}
            />
          ) : (
            <div className='d-flex '>
              <span className='pe-2'>Owner operator?</span>
              <Form.Check
                type='switch'
                name={fieldName}
                value={value}
                onChange={formik.handleChange}
              />
            </div>
          )
        ) : (
          <Form.Control
            as='textarea'
            rows={3}
            name={fieldName}
            value={value}
            onChange={formik.handleChange}
          />
        )}
        {error && touchStatus && (
          <div className='ms-1 mt-1  text-danger'>{error}</div>
        )}
      </Form.Group>
    );
  };

  const pictureEntryField = (
    fieldNames,
    label,
    type,
    formik,
    isRequired,
    touchStatus,
    error
  ) => {
    return (
      <Form.Group className='mb-3' controlId={fieldNames[1]}>
        <Form.Label>
          {label} {isRequired && <span style={{ color: 'red' }}>*</span>}
        </Form.Label>

        <Form.Control
          type={type}
          accept='.png, .jpg, .jpeg'
          onChange={(event) =>
            formik.setFieldValue(fieldNames[1], event.currentTarget.files[0])
          }
        />
        {error && touchStatus && (
          <div className='ms-1 mt-1  text-danger'>{error}</div>
        )}

        <Collapse
          in={
            formik.values[fieldNames[0]] !== '' ||
            formik.values[fieldNames[1]] !== null
          }
        >
          <div className='mt-4 animate__animated animate__fadeIn animate__delay-0.5s'>
            <span>Selected image: </span>
            <img
              src={
                formik.values[fieldNames[1]] !== null
                  ? URL.createObjectURL(formik.values[fieldNames[1]])
                  : formik.values[fieldNames[0]]
              }
              alt='Uploaded Image'
              className='ms-2'
              style={{ maxWidth: '200px' }}
            />
          </div>
        </Collapse>
      </Form.Group>
    );
  };

  const selectEntryField = (
    fieldName,
    label,
    formik,
    isRequired,
    value,
    touchStatus,
    error,
    options
  ) => {
    return (
      <Form.Group className='mb-3 me-2' controlId={fieldName}>
        <Form.Label>
          {label} {isRequired && <span style={{ color: 'red' }}>*</span>}
        </Form.Label>
        <Form.Select
          name={fieldName}
          value={value}
          onChange={formik.handleChange}
        >
          {options.map((option, index) => (
            <option key={index} value={option.truckPlateNumber}>
              {option.truckPlateNumber}
            </option>
          ))}
        </Form.Select>
        {error && touchStatus && (
          <div className='ms-1 mt-1  text-danger'>{error}</div>
        )}
      </Form.Group>
    );
  };

  const loader = (
    <div
      className='d-flex align-items-center justify-content-center'
      style={{ height: '100vh' }}
    >
      <img src={loading} style={{ width: '50px', height: '50px' }} />
    </div>
  );

  return (
    <StyleContext.Provider
      value={{
        darkThemeStyle,
        searchDivStyle,
        searchInputStyle,
        modalHeaderStyle,
        modalFooterStyle,
        paginationBtnStyle,
        primaryBtnStyle,
        backButtonStyle,
        partition,
        showTitle,
        showTextFieldValueInDisplay,
        textSearch,
        primaryBtn,
        submitBtn,
        addBtn,
        deleteBtn,
        tableBtn,
        backBtn,
        textEntryField,
        pictureEntryField,
        selectEntryField,
        loader,
      }}
    >
      {children}
    </StyleContext.Provider>
  );
};

export default StyleProvider;

import React from "react";

const PrivacyPolicyRoute = () => {
  return (
    <div className="p-4" style={{ textAlign: "left" }}>
      <h1>Privacy Policy for Drivers Log App</h1>
      <p>Last updated: 12 Dec 2023</p>

      <h2>Introduction</h2>
      <p>
        Our Drivers Log App ("the App") is committed to protecting the privacy
        and security of your personal information. The App is designed for
        company drivers to record their trip logs, which assists in documenting
        trips and calculating payments according to the company's rate.
      </p>

      <h2>Information We Collect</h2>
      <p>
        The App collects information necessary to track your trips and calculate
        payments. This includes:
      </p>
      <ul>
        <li>Trip details (e.g., start and end locations, duration, mileage)</li>
      </ul>

      <h2>How We Use Your Information</h2>
      <p>The information collected is used to:</p>
      <ul>
        <li>Create and maintain trip logs</li>
        <li>Calculate payments based on company rates</li>
        <li>Communicate with company administrators</li>
      </ul>

      <h2>Sharing Your Information</h2>
      <p>
        We only share your information with company administrators for the
        purposes of trip logging and payment calculations. We do not sell or
        rent personal information to third parties.
      </p>

      <h2>Data Security</h2>
      <p>
        We implement various security measures to ensure the safety of your
        personal information.
      </p>

      <h2>Changes to Our Privacy Policy</h2>
      <p>
        We may update this privacy policy from time to time. We will notify you
        of any changes by updating the 'Last updated' date of this policy.
      </p>

      <h2>Contact Information</h2>
      <p>
        If you have any questions or concerns about this policy, please contact
        us at <a href="mailto:zelzoat@gmail.com">zelzoat@gmail.com</a>.
      </p>
    </div>
  );
};

export default PrivacyPolicyRoute;

import { AiFillCheckCircle } from 'react-icons/ai';
import { VscError } from 'react-icons/vsc';

import { useState } from 'react';
import { useStyle } from '../../context/StyleContext';

const UsersTable = ({ headerData, currentItems, handleClick, classes }) => {
  // This component is made to display the data of drivers in a tabular form...

  const [current, setCurrent] = useState(-1);
  const { tableBtn } = useStyle();

  const tableColumnCode = (content, index) => {
    return (
      <td className={`bg-transparent ${classes.column}`}>
        <p
          className={`${
            index == current ? classes.contentLight : classes.content
          } text-center`}
        >
          {content}
        </p>
      </td>
    );
  };
  return (
    <>
      <thead>
        {/* Displaying the header data i.e, top row of the table */}
        <tr className={`${classes.headRow}`}>
          {headerData.map((item, index) => {
            return (
              <th
                className={`pb-3 bg-transparent text-start border-bottom-0`}
                key={index}
              >
                <h6 style={{ padding: '0px 3px' }} className='text-center'>
                  {item.title}
                </h6>
              </th>
            );
          })}
        </tr>
      </thead>

      <tbody>
        {/* Displaying the data of drivers where column 1,2,3,4,5 contain profile pic, name, email, number and action button */}
        {currentItems.map((item, index) => {
          return (
            <tr
              className={`${classes.row}`}
              key={index}
              onClick={() => handleClick(item)}
              onMouseEnter={() => setCurrent(index)}
              onMouseLeave={() => setCurrent(-1)}
            >
              <td
                className={`bg-transparent rounded-start-4 border-bottom-0 ${classes.column}`}
              >
                {item.isAvailable ? (
                  <AiFillCheckCircle className='mt-3 text-success' size={27} />
                ) : (
                  <VscError className='mt-3 text-danger' size={27} />
                )}
              </td>

              <td className={`bg-transparent text-center ${classes.column}`}>
                <div className={classes.dpContainer}>
                  <div
                    className={`${classes.dp}`}
                    style={{
                      backgroundImage: `url(${item.profilePictureURL})`,
                    }}
                  ></div>
                </div>
              </td>

              {tableColumnCode(item.name, index)}
              {tableColumnCode(item.email, index)}
              {tableColumnCode(item.contactNumber, index)}

              <td className={`bg-transparent rounded-end-4 `}>
                {tableBtn(
                  () => handleClick(item),
                  'Show Driver',
                  current === index
                )}
              </td>
            </tr>
          );
        })}
      </tbody>
    </>
  );
};

export default UsersTable;

import React from 'react';
import Button from 'react-bootstrap/Button';
import { AiOutlineArrowLeft, AiOutlineArrowRight } from 'react-icons/ai';
import { useStyle } from '../context/StyleContext';

const Pagination = ({ currentPage, totalPages, onPageChange }) => {
  const { paginationBtnStyle } = useStyle();
  const pageNumbers = [];
  for (
    let i = Math.max(1, currentPage - 3);
    i <= Math.min(totalPages, currentPage + 3);
    i++
  ) {
    pageNumbers.push(i);
  }

  return (
    <div className='pagination my-4 d-flex justify-content-center'>
      <Button
        variant='outline-secondary'
        style={paginationBtnStyle}
        className='d-flex align-items-center'
        onClick={() => onPageChange(currentPage - 1)}
        disabled={currentPage === 1}
      >
        <AiOutlineArrowLeft size={15} />
      </Button>
      {pageNumbers.map((pageNumber) => (
        <Button
          key={pageNumber}
          style={
            currentPage !== pageNumber
              ? {
                  ...paginationBtnStyle,
                  color: 'var(--primaryColor)',
                  background: 'white',
                }
              : { ...paginationBtnStyle, color: 'white' }
          }
          className='d-flex align-items-center'
          onClick={() => onPageChange(pageNumber)}
          active={currentPage === pageNumber}
        >
          {pageNumber}
        </Button>
      ))}
      <Button
        variant='outline-secondary'
        style={paginationBtnStyle}
        className='d-flex align-items-center'
        onClick={() => onPageChange(currentPage + 1)}
        disabled={currentPage === totalPages}
      >
        <AiOutlineArrowRight size={15} />
      </Button>
    </div>
  );
};

export default Pagination;

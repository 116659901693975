import ManipulateDataTemplate from '../../utils/DataManipulationTemplate/ManipulateDataTemplate';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { useStyle } from '../../context/StyleContext';

const ManageTruckData = ({
  title,
  data,
  show,
  handleShow,
  handleSubmit,
  isLoading,
  setIsLoading,
}) => {
  const { textEntryField, pictureEntryField } = useStyle();

  const validationSchema = Yup.object().shape({
    truckPlateNumber: Yup.string().required('Truck plate number is required'),
    truckModel: Yup.string().required('Truck model is required'),
    registeredYear: Yup.string().required(
      'Truck registration year is required'
    ),
    file:
      data === undefined && Yup.mixed().required('Truck picture is required'),
  });

  let formikInitializer =
    data !== undefined
      ? { ...data, file: null }
      : {
          truckPlateNumber: '',
          truckModel: '',
          registeredYear: '',
          truckPictureURL: '',
          file: null,
        };

  const formik = useFormik({
    initialValues: formikInitializer,
    validationSchema,
    onSubmit: (values, { resetForm }) => {
      setIsLoading(true);

      setTimeout(async () => {
        const isDone = await handleSubmit(values);
        if (isDone) {
          formikInitializer.file = null;
          resetForm(formikInitializer);

          handleShow();
          setIsLoading(false);
        }
      }, 1000);
    },
  });

  return (
    <ManipulateDataTemplate
      title={title}
      show={show}
      handleShow={handleShow}
      formik={formik}
      isLoading={isLoading}
    >
      {textEntryField(
        'truckPlateNumber',
        'Truck Plate Number',
        'text',
        formik,
        true,
        formik.values.truckPlateNumber,
        formik.touched.truckPlateNumber,
        formik.errors.truckPlateNumber
      )}
      {textEntryField(
        'truckModel',
        'Truck Model',
        'text',
        formik,
        true,
        formik.values.truckModel,
        formik.touched.truckModel,
        formik.errors.truckModel
      )}
      {textEntryField(
        'registeredYear',
        'Registration Year',
        'text',
        formik,
        true,
        formik.values.registeredYear,
        formik.touched.registeredYear,
        formik.errors.registeredYear
      )}
      {pictureEntryField(
        ['truckPictureURL', 'file'],
        'Truck Picture',
        'file',
        formik,
        true,
        formik.touched.file,
        formik.errors.file
      )}
    </ManipulateDataTemplate>
  );
};

export default ManageTruckData;

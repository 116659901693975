import ManipulateDataTemplate from '../../utils/DataManipulationTemplate/ManipulateDataTemplate';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { useStyle } from '../../context/StyleContext';
import { useEffect } from 'react';
import { useData } from '../../context/DataContext';

const ManageUserData = ({
  title,
  data,
  show,
  handleShow,
  handleSubmit,
  isLoading,
  setIsLoading,
}) => {
  const { textEntryField, selectEntryField } = useStyle();
  const { trucks } = useData();

  const validationSchema = Yup.object().shape({
    name: Yup.string().required('Name is required'),
    contactNumber: Yup.string().required('Number is required'),
    address: Yup.string().required('Address is required'),
    licenseNumber: Yup.string().required('License number is required'),
    rate: Yup.string().required('Rate is required'),

    email: Yup.string()
      .required('Email is required')
      .email('Invalid email format'),

    password: Yup.string()
      .required('Password is required')
      .min(8, 'Password must be at least 8 characters long'),
  });

  const formikInitializer =
    data !== undefined
      ? { ...data, isPasswordUpdated: false }
      : {
          email: '',
          password: '',
          name: '',
          contactNumber: '',
          address: '',
          licenseNumber: '',
          rate: '',
          truckPlateNumber: '',
        };

  const formik = useFormik({
    initialValues: formikInitializer,
    validationSchema,
    onSubmit: (values, { resetForm }) => {
      setIsLoading(true);

      setTimeout(async () => {
        const isDone = await handleSubmit(values);
        if (isDone) {
          resetForm(formikInitializer);
          handleShow();
          setIsLoading(false);
        }
      }, 1000);
    },
  });

  useEffect(() => {
    if (formik.values.password !== formikInitializer.password)
      formik.values.isPasswordUpdated = true;
    else formik.values.isPasswordUpdated = false;
  }, [formik.values.password]);

  return (
    <ManipulateDataTemplate
      title={title}
      show={show}
      handleShow={handleShow}
      formik={formik}
      isLoading={isLoading}
    >
      {textEntryField(
        'email',
        'Email',
        'text',
        formik,
        true,
        formik.values.email,
        formik.touched.email,
        formik.errors.email
      )}
      {textEntryField(
        'password',
        'Password',
        'password',
        formik,
        true,
        formik.values.password,
        formik.touched.password,
        formik.errors.password
      )}
      {textEntryField(
        'name',
        'Name',
        'text',
        formik,
        true,
        formik.values.name,
        formik.touched.name,
        formik.errors.name
      )}
      {textEntryField(
        'contactNumber',
        'Number',
        'text',
        formik,
        true,
        formik.values.contactNumber,
        formik.touched.contactNumber,
        formik.errors.contactNumber
      )}
      {textEntryField(
        'address',
        'Address',
        'text',
        formik,
        true,
        formik.values.address,
        formik.touched.address,
        formik.errors.address
      )}
      {textEntryField(
        'licenseNumber',
        'License Number',
        'text',
        formik,
        true,
        formik.values.licenseNumber,
        formik.touched.licenseNumber,
        formik.errors.licenseNumber
      )}
      {textEntryField(
        'rate',
        'Rate',
        'text',
        formik,
        true,
        formik.values.rate,
        formik.touched.rate,
        formik.errors.rate
      )}

      {selectEntryField(
        'truckPlateNumber',
        'Truck Plate Number',
        formik,
        false,
        formik.values.truckPlateNumber,
        undefined,
        undefined,
        trucks
      )}
    </ManipulateDataTemplate>
  );
};

export default ManageUserData;

import ManipulateDataTemplate from '../../../utils/DataManipulationTemplate/ManipulateDataTemplate';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { useStyle } from '../../../context/StyleContext';

const ManageDeduction = ({
  title,
  show,
  handleShow,
  handleSubmit,
  isLoading,
  setIsLoading,
}) => {
  const { textEntryField } = useStyle();

  const validationSchema = Yup.object().shape({
    title: Yup.string().required('Title is required'),
    description: Yup.string().required('Description is required'),
    cost: Yup.number()
      .required('Cost is required')
      .typeError('Please enter a valid number'),
  });

  let formikInitializer = {
    title: '',
    description: '',
    cost: '',
  };

  const formik = useFormik({
    initialValues: formikInitializer,
    validationSchema,
    onSubmit: (values, { resetForm }) => {
      setIsLoading(true);

      setTimeout(() => {
        handleSubmit(values);
        resetForm(formikInitializer);

        handleShow();
        setIsLoading(false);
      }, 1000);
    },
  });

  return (
    <ManipulateDataTemplate
      title={title}
      show={show}
      handleShow={handleShow}
      formik={formik}
      isLoading={isLoading}
    >
      {textEntryField(
        'title',
        'Title',
        'text',
        formik,
        true,
        formik.values.title,
        formik.touched.title,
        formik.errors.title
      )}

      {textEntryField(
        'description',
        'Description',
        'textarea',
        formik,
        true,
        formik.values.description,
        formik.touched.description,
        formik.errors.description
      )}

      {textEntryField(
        'cost',
        'Cost',
        'number',
        formik,
        true,
        formik.values.cost,
        formik.touched.cost,
        formik.errors.cost
      )}
    </ManipulateDataTemplate>
  );
};

export default ManageDeduction;

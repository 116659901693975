import classes from "./TableTemplate.module.css";
import Pagination from "../Pagination";
import DisplayModalTemplate from "../DisplayModalTemplate/DisplayModalTemplate";
import Table from "react-bootstrap/Table";
import { useEffect, useState } from "react";

const TableTemplate = ({
  pageTitle,
  headerData,
  tableData,
  CRUD,
  TableComponent,
  DisplayModalComponent,
  EditModalComponent,
}) => {
  // This component is made to display the data provided to it in a tabular form...

  // Pagination related data and processing
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 5;
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = tableData.slice(indexOfFirstItem, indexOfLastItem);
  const handlePageChange = (pageNumber) => setCurrentPage(pageNumber);
  useEffect(() => setCurrentPage(1), [tableData]);

  // Selection of data and displaying the modal for data
  const [displayDataShow, setDisplayDataShow] = useState(false);
  const [selectedData, setSelectedData] = useState(null);
  const toggleDisplayDataShow = () => setDisplayDataShow(!displayDataShow);

  const handleClick = (data) => {
    setSelectedData(data);
    toggleDisplayDataShow();
  };

  return (
    <>
      {selectedData !== null && (
        // This modal is used to display data of any row clicked
        <DisplayModalTemplate
          title={pageTitle}
          data={selectedData}
          CRUD={CRUD}
          displayDataShow={displayDataShow}
          toggleDisplayDataShow={toggleDisplayDataShow}
          DisplayModalComponent={DisplayModalComponent}
          EditModalComponent={EditModalComponent}
        />
      )}
      <Table responsive hover className={`${classes.table}`}>
        <TableComponent
          headerData={headerData}
          currentItems={currentItems}
          handleClick={handleClick}
          classes={classes}
          CRUD={CRUD}
        />
      </Table>
      <Pagination
        currentPage={currentPage}
        totalPages={Math.ceil(tableData.length / itemsPerPage)}
        onPageChange={handlePageChange}
      />
    </>
  );
};

export default TableTemplate;

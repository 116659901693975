import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import { toast } from "react-toastify";

const UserContext = React.createContext();
export const useUser = () => {
  return useContext(UserContext);
};

const UserProvider = ({ children }) => {
  const [user, setUser] = useState(null);

  useEffect(() => {
    const storedToken = localStorage.getItem("token");
    if (storedToken) getAdmin(storedToken);
  }, []);

  const toastDetails = {
    position: "bottom-left",
    autoClose: 2000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "light",
  };

  const adminsLink = "https://api.bymeer.com/api/admin";
  // const adminsLink = "http://localhost:8000/api/admin";
  const login = async (data) => {
    axios
      .post(`${adminsLink}/login`, data)
      .then((response) => {
        const { token, ...userData } = response.data;

        localStorage.setItem("token", token);

        toast.success("Successfully logged in", toastDetails);
        setUser({ ...userData, token });
        getAdmin(token);
      })
      .catch((error) => {
        console.error("Error occurred while logging in:", error);
        toast.error(error.response.data.message, toastDetails);
      });
  };

  const logout = () => {
    return new Promise((resolve, reject) => {
      try {
        localStorage.removeItem("token");

        setUser(null);
        resolve();
      } catch (error) {
        reject(error);
      }
    });
  };

  //---------------------------Admin related CRUD----------------------------------------------------

  const getAdmin = async (token) => {
    axios
      .get(`${adminsLink}`, {
        headers: {
          Authorization: `bearer ${token}`,
        },
      })
      .then((response) => {
        const adminData = response.data;
        setUser({ ...adminData, token: token });
      })
      .catch((error) => {
        console.error("Error fetching admin:", error);
      });
  };

  const updateAdmin = async (data) => {
    try {
      const response = await axios.put(`${adminsLink}`, data, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `bearer ${user.token}`,
        },
      });

      toast.success("Successfully updated admin!", toastDetails);
      getAdmin(user.token);
    } catch (error) {
      console.error("Error updating admin:", error);
      if (
        error.response &&
        error.response.data &&
        error.response.data.message
      ) {
        toast.error(error.response.data.message, toastDetails);
      } else {
        toast.error("An error occurred while updating the admin", toastDetails);
      }
    } finally {
      return true;
    }
  };

  const updateAdminPassword = async (data) => {
    try {
      const response = await axios.put(`${adminsLink}/update-password`, data, {
        headers: {
          Authorization: `bearer ${user.token}`,
        },
      });

      toast.success("Successfully updated admin password!", toastDetails);
      getAdmin(user.token);
    } catch (error) {
      console.error("Error updating admin password:", error);
      if (
        error.response &&
        error.response.data &&
        error.response.data.message
      ) {
        toast.error(error.response.data.message, toastDetails);
      } else {
        toast.error(
          "An error occurred while updating the admin password",
          toastDetails
        );
      }
    } finally {
      return true;
    }
  };

  return (
    <UserContext.Provider
      value={{
        user,
        login,
        logout,
        updateAdmin,
        updateAdminPassword,
      }}
    >
      {children}
    </UserContext.Provider>
  );
};
export default UserProvider;

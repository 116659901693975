import classes from './AdminLogDisplay.module.css';
import LogCalculationTable from '../../Tables/Logs/LogCalculationTable';
import AdminLogDetails from './AdminLogDetails';

import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';

import { Button } from 'react-bootstrap';
import { AiOutlineArrowLeft } from 'react-icons/ai';
import { useRef } from 'react';
import { useStyle } from '../../../context/StyleContext';

const AdminLogDisplay = ({ adminLog, driverLog, toggleShowAdminLog }) => {
  const { partition, primaryBtn } = useStyle();

  const adminLogRef = useRef(null);
  const driverLogRef = useRef(null);

  const downloadPdfDocument = (ref, isAdminLog = false) => {
    const input = ref.current;

    html2canvas(input, {
      scale: 1, // Adjust scale as needed
      logging: true,
      useCORS: true,
    }).then((canvas) => {
      // Assuming A4 size page (width: 210mm, height: 297mm) in landscape orientation
      const imgWidth = 297;
      const pageHeight = 210;
      const imgHeight = (canvas.height * imgWidth) / canvas.width;
      let heightLeft = imgHeight;

      const pdf = new jsPDF('l', 'mm', 'a4');
      let position = 0;

      // Add heading to the PDF
      const headingText = 'Travel Expense Report';
      const fontSize = 18;
      const textWidth =
        (pdf.getStringUnitWidth(headingText) * fontSize) /
        pdf.internal.scaleFactor;

      const pageWidth = pdf.internal.pageSize.width;
      const xPos = (pageWidth - textWidth) / 2;

      pdf.setFontSize(fontSize);
      pdf.setTextColor(8, 59, 130);

      pdf.text(headingText, xPos, 20); // Adjust the Y position (here 20) as needed

      // Add image to PDF
      pdf.addImage(
        canvas.toDataURL('image/svg'),
        'SVG',
        0,
        position + 30, // Offset to leave space below the heading
        imgWidth,
        imgHeight
      );
      heightLeft -= pageHeight;

      // Check if the entire table fits in one page
      while (heightLeft >= 0) {
        position = heightLeft - imgHeight;
        pdf.addPage();
        pdf.text(headingText, xPos, 20); // Adding heading on each new page
        pdf.addImage(
          canvas.toDataURL('image/svg'),
          'SVG',
          0,
          position + 30, // Offset to leave space below the heading
          imgWidth,
          imgHeight
        );
        heightLeft -= pageHeight;
      }

      pdf.save(
        `${driverLog?.tripId}'s ${isAdminLog ? 'Admin' : 'Driver'} Log.pdf`
      );
    });
  };

  return (
    <div>
      <div className='mb-4 border-bottom' style={{ maxHeight: '15vh' }}>
        <div className='d-flex justify-content-between'>
          <div className='d-flex align-items-center'>
            <Button
              variant='outline-primary'
              className='me-2 border-0'
              onClick={toggleShowAdminLog}
            >
              <AiOutlineArrowLeft size={20} />
            </Button>
            <h2 className='pt-2' style={{ color: 'var(--primaryColor)' }}>
              Travel Expense Report
            </h2>
          </div>
          <div className='align-self-center'>
            <span className='me-1'>
              {primaryBtn('Print Admin Log', () =>
                downloadPdfDocument(adminLogRef, true)
              )}
            </span>
            {primaryBtn('Print Driver Log', () =>
              downloadPdfDocument(driverLogRef)
            )}
          </div>
        </div>
      </div>

      <div
        className={`mx-2 px-2 overflow-y-auto overflow-x-hidden `}
        ref={adminLogRef}
      >
        <div ref={driverLogRef} className='z-0'>
          <div className={`${classes.watermarkLogo} z-3`} />
          <AdminLogDetails adminLog={adminLog} driverLog={driverLog} />

          <div className='px-3'>{partition}</div>

          <LogCalculationTable log={driverLog} calledInAdminLog={true} />
        </div>
        <div className='px-3'>{partition}</div>

        <LogCalculationTable
          log={{ ...adminLog, driverLogTotalAmount: driverLog.totalAmount }}
          isAdminLog={true}
          calledInAdminLog={true}
        />
      </div>
    </div>
  );
};

export default AdminLogDisplay;

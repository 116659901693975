import { AiFillCheckCircle } from 'react-icons/ai';
import { VscError } from 'react-icons/vsc';

import { useUser } from '../../../context/UserContext';
import { useStyle } from '../../../context/StyleContext';
const AdminLogDetails = ({ adminLog, driverLog }) => {
  const { user } = useUser();
  const { showTextFieldValueInDisplay } = useStyle();

  return (
    <div class='pe-4 container'>
      <div class='py-1 row'>
        <div class='col-sm-6 text-start'>
          {showTextFieldValueInDisplay('Driver', driverLog?.currentDriver.name)}
        </div>
        <div class='col-sm-6 text-sm-end text-start'>
          {showTextFieldValueInDisplay('Authorized By', user.name)}
        </div>
      </div>

      <div class='py-1 row'>
        <div class='col-sm-6 text-start'>
          {showTextFieldValueInDisplay('Date Submitted', driverLog?.createdAt)}
        </div>
        <div class='col-sm-6 text-sm-end text-start'>
          {showTextFieldValueInDisplay('Date Authorized', adminLog?.updatedAt)}
        </div>
      </div>

      <div class='py-1 row'>
        <div class='col-sm-6 text-start'>
          {showTextFieldValueInDisplay(
            'Per Mile Reimbursement',
            `$${driverLog?.rate}`
          )}
        </div>
        <div class='col-sm-6 text-sm-end text-start'>
          {showTextFieldValueInDisplay(
            'Total Mile Reimbursement',
            `$${driverLog?.rate * driverLog?.miles}`
          )}
        </div>
      </div>

      <div class='py-1 row'>
        <div class='col-sm-6 text-start'>
          {showTextFieldValueInDisplay('Dispatch', `${adminLog?.dispatcher}%`)}
        </div>
        <div class='col-sm-6 text-sm-end text-start'>
          <h5 className={`mb-2`}>
            <span style={{ color: 'var(--primaryColor)' }}>
              Owner Operator:{' '}
            </span>
            {adminLog?.ownerOperator ? (
              <AiFillCheckCircle className='text-success' size={22} />
            ) : (
              <VscError className='text-danger' size={22} />
            )}
          </h5>
        </div>
      </div>

      <div class='py-1 row'>
        <div class='col-sm-6 text-start'>
          {showTextFieldValueInDisplay(
            'Start Location',
            `${driverLog?.startLocation}`
          )}
        </div>
        <div class='col-sm-6  text-sm-end text-start'>
          {showTextFieldValueInDisplay(
            'End Location',
            `${driverLog?.endLocation}`
          )}
        </div>
      </div>

      <div class='py-1 row'>
        <div class='col-sm-6 text-start'>
          {showTextFieldValueInDisplay(
            'Period',
            `${driverLog?.startDate} - ${driverLog?.endDate}`
          )}
        </div>
      </div>
    </div>
  );
};

export default AdminLogDetails;

import ManipulateDataTemplate from "../../../utils/DataManipulationTemplate/ManipulateDataTemplate";
import * as Yup from "yup";
import { FaPlus } from "react-icons/fa6";
import { Button, Form } from "react-bootstrap";
import { useFormik } from "formik";
import { useStyle } from "../../../context/StyleContext";
import { useEffect, useState } from "react";

const ManageAdminLogData = ({
  title,
  driversLogData,
  show,
  handleShow,
  handleSubmit,
  isLoading,
  setIsLoading,
}) => {
  const { textEntryField } = useStyle();
  const [currentTotal, setCurrentTotal] = useState(0);
  const [dieselExpenses, setDieselExpenses] = useState([0]);
  const [totalDieselExpense, setTotalDieselExpense] = useState(0);

  useEffect(() => {
    const totalDieselExpense = dieselExpenses.reduce((total, expense) => {
      if (expense) return total + expense;
      else return total;
    }, 0);

    setTotalDieselExpense(totalDieselExpense);
  }, [dieselExpenses]);

  const validationSchema = Yup.object().shape({
    dispatcher: Yup.number().required("Dispatcher is required"),
    miles: Yup.number().required("Miles are required"),
    companyRate: Yup.number()
      .required("Company Rate is required")
      .test(
        "minimumCompanyRate",
        `Current Total is less than Total Trip Cost`,
        function (value) {
          const currentMiles = this.parent.miles;
          const partialTotal = currentMiles * (value ? value : 0);

          const currentDispatcherPercentage = this.parent.dispatcher;
          const currentDispatcherAmount =
            partialTotal *
            ((currentDispatcherPercentage ? currentDispatcherPercentage : 0) /
              100);

          setCurrentTotal(partialTotal - currentDispatcherAmount);
          return true;
        }
      ),
  });

  let formikInitializer = {
    dispatcher: 0,
    miles: driversLogData !== undefined ? driversLogData.miles : 0,
    companyRate: 0,
    ownerOperator: false,
  };

  const formik = useFormik({
    initialValues: formikInitializer,
    validationSchema,
    onSubmit: (values, { resetForm }) => {
      setIsLoading(true);
      setTimeout(() => {
        handleSubmit({
          ...values,
          dieselExpenses: dieselExpenses,
          totalDieselExpense: totalDieselExpense,
        });
        resetForm(formikInitializer);
        handleShow();
        setIsLoading(false);
      }, 1000);
    },
  });

  // Function to handle adding a new fuel expense
  const addDieselExpense = () => {
    const newFuelExpenses = [...dieselExpenses, 0];
    setDieselExpenses(newFuelExpenses);
  };

  return (
    <ManipulateDataTemplate
      title={title}
      show={show}
      handleShow={handleShow}
      formik={formik}
      isLoading={isLoading}
    >
      {textEntryField(
        "miles",
        "Miles",
        "number",
        formik,
        true,
        formik.values.miles,
        formik.touched.miles,
        formik.errors.miles
      )}

      {textEntryField(
        "companyRate",
        "Company Rate",
        "number",
        formik,
        true,
        formik.values.companyRate,
        formik.touched.companyRate,
        formik.errors.companyRate
      )}

      {textEntryField(
        "dispatcher",
        "Dispatch %",
        "number",
        formik,
        true,
        formik.values.dispatcher,
        formik.touched.dispatcher,
        formik.errors.dispatcher
      )}

      {textEntryField(
        "ownerOperator",
        "Owner Operator",
        "switch",
        formik,
        true,
        formik.values.ownerOperator,
        formik.touched.ownerOperator,
        formik.errors.ownerOperator
      )}

      {dieselExpenses.map((expense, index) => (
        <Form.Group
          className="mb-3 me-2"
          controlId={`dieselExpense.${index}`}
          key={index}
        >
          <Form.Label>Diesel Expense</Form.Label>

          <Form.Control
            type="number"
            step="0.01"
            name={`dieselExpense${index}`}
            value={expense}
            onChange={(e) => {
              const updatedDieselExpenses = [...dieselExpenses];

              updatedDieselExpenses[index] = parseInt(e.target.value, 10);

              setDieselExpenses(updatedDieselExpenses);
            }}
          />
        </Form.Group>
      ))}

      <div className="my-1 col-12 d-flex justify-content-center">
        <Button
          variant="outline-primary"
          className="pb-2 rounded-circle fw-lighter "
          onClick={addDieselExpense}
        >
          <FaPlus size={15} />
        </Button>
      </div>

      <p>
        Current Total: <span className="fw-semibold">${currentTotal}</span>
      </p>
      <p>
        Driver Payout Amount:{" "}
        <span className="fw-semibold">${driversLogData.totalAmount}</span>
      </p>
      <p>
        Total Diesel Expense:{" "}
        <span className="fw-semibold">${totalDieselExpense}</span>
      </p>
    </ManipulateDataTemplate>
  );
};

export default ManageAdminLogData;

import classes from '../../../utils/TableTemplate/TableTemplate.module.css';
import Pagination from '../../../utils/Pagination';

import { Table } from 'react-bootstrap';
import { FaLongArrowAltRight } from 'react-icons/fa';
import { FaTruckFast } from 'react-icons/fa6';
import { useState, useEffect } from 'react';
import { useStyle } from '../../../context/StyleContext';

const DriversLogsTable = ({ tableData, handleClick }) => {
  const { tableBtn } = useStyle();

  // Pagination related data and processing
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 15;
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = tableData.slice(indexOfFirstItem, indexOfLastItem);
  const handlePageChange = (pageNumber) => setCurrentPage(pageNumber);
  useEffect(() => setCurrentPage(1), [tableData]);

  const [current, setCurrent] = useState(-1);

  return (
    <>
      <Table responsive hover className={`${classes.table}`}>
        <tbody>
          {/* Displaying the data of drivers where column 1,2,3,4,5 contain profile pic, name, email, number and action button */}
          {currentItems.map((item, index) => {
            return (
              <tr
                className={`${classes.row}`}
                key={index}
                onClick={() => handleClick(item)}
                onMouseEnter={() => setCurrent(index)}
                onMouseLeave={() => setCurrent(-1)}
              >
                <td className={`bg-transparent rounded-start-4 border-0`}>
                  <div className='pt-2 d-flex justify-content-center'>
                    <span
                      style={{
                        color: index == current ? 'white' : 'rgb(78, 78, 78)',
                      }}
                    >
                      <FaTruckFast size={40} />
                    </span>
                  </div>
                </td>

                <td className={`bg-transparent text-start border-0`}>
                  <h5
                    className='text-uppercase'
                    style={{
                      color: index == current ? 'white' : 'var(--headingColor)',
                    }}
                  >
                    {item.tripId}
                  </h5>
                  <p
                    className={classes.basicText}
                    style={{
                      color: index == current ? 'white' : '',
                    }}
                  >
                    Location( {item.startLocation}
                    <span className='px-1'>
                      <FaLongArrowAltRight size={10} />
                    </span>
                    {item.endLocation} )<span className='px-2'>-</span>
                    {item.totalAmount}$
                  </p>
                </td>

                <td className={`bg-transparent rounded-end-4 border-0`}>
                  {tableBtn(
                    () => handleClick(item),
                    'Show Driver',
                    current === index
                  )}
                </td>
              </tr>
            );
          })}
        </tbody>
      </Table>
      <Pagination
        currentPage={currentPage}
        totalPages={Math.ceil(tableData.length / itemsPerPage)}
        onPageChange={handlePageChange}
      />
    </>
  );
};

export default DriversLogsTable;

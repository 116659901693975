import LoginProtectedRoute from "./routes/LoginProtectedRoute";
import PanelProtectedRoute from "./routes/PanelProtectedRoute";
import Login from "./views/Login";
import Panel from "./views/Panel";
import Drivers from "./pages/Drivers";
import Trucks from "./pages/Trucks";
import DriversLogs from "./pages/DriversLogs/DriversLogs";
import UpdateAdminPassword from "./pages/Admin/UpdateAdminPassword";
import UpdateAdminDetails from "./pages/Admin/UpdateAdminDetails";
import { Outlet } from "react-router-dom";
import { Routes, Route } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import { useUser } from "./context/UserContext";
import "./App.css";
import "react-toastify/dist/ReactToastify.css";
import PrivacyPolicyRoute from "./routes/PrivacyPolicyRoute";

function App() {
  const { user } = useUser();
  return (
    <div className="App">
      <Routes>
        <Route
          path="/"
          element={
            <PanelProtectedRoute user={user}>
              <Panel />
            </PanelProtectedRoute>
          }
        >
          <Route path="drivers" element={<Drivers />} />
          <Route path="trucks" element={<Trucks />} />
          <Route path="drivers-logs" element={<DriversLogs />} />

          <Route path="admin" element={<Outlet />}>
            <Route path="update-details" element={<UpdateAdminDetails />} />
            <Route path="update-password" element={<UpdateAdminPassword />} />
          </Route>
        </Route>

        <Route
          path="/login"
          element={
            <LoginProtectedRoute user={user}>
              <Login />
            </LoginProtectedRoute>
          }
        />
        <Route path="/privacy-policy" element={<PrivacyPolicyRoute />} />
      </Routes>
      <ToastContainer />
    </div>
  );
}

export default App;

import ManageAdminLogData from '../../DataManagementModals/Logs/ManageAdminLogData';

import { Dropdown, SplitButton, Button } from 'react-bootstrap';
import { AiOutlineArrowLeft } from 'react-icons/ai';
import { useState } from 'react';
import { useData } from '../../../context/DataContext';

const DriverLogHeader = ({ data, toggleSelectedData, toggleShowAdminLog }) => {
  const { updateDriverLogStatus, deleteDriverLog, postAdminLog } = useData();

  const [isLoading, setIsLoading] = useState(false);

  // Admin Log button related code
  const [showManageAdminLog, setShowManageAdminLog] = useState(false);
  const handleAdminLogShow = () => setShowManageAdminLog(!showManageAdminLog);

  const handleAdminLogSubmit = async (values) => {
    await postAdminLog(values, data._id);

    await updateDriverLogStatus(
      { status: 'approved', initialStatus: data.status },
      data._id
    );

    toggleSelectedData();
  };

  // Log Status related code
  const [selectedStatus, setSelectedStatus] = useState(null);
  const handleSelectStatus = (eventKey) => setSelectedStatus(eventKey);

  const handleLogStatusChange = async (status) => {
    if (status) {
      setIsLoading(true);

      if (status === 'delete') await deleteDriverLog(data._id);
      else
        await updateDriverLogStatus(
          { status: status, initialStatus: data.status },
          data._id
        );

      setTimeout(() => {
        setIsLoading(false);
        toggleSelectedData();
      }, 1000);
    }
  };

  const conditionalStyling = (property) => {
    return selectedStatus === 'delete'
      ? `${property}-danger`
      : selectedStatus === 'rejected'
      ? `${property}-warning`
      : selectedStatus === 'pending'
      ? `${property}-success`
      : `${property}-primary`;
  };

  return (
    <div style={{ maxHeight: '15vh' }}>
      {showManageAdminLog && (
        <ManageAdminLogData
          title='Create new admin log'
          driversLogData={data}
          show={showManageAdminLog}
          handleShow={handleAdminLogShow}
          handleSubmit={handleAdminLogSubmit}
          isLoading={isLoading}
          setIsLoading={setIsLoading}
        />
      )}
      <div className='mx-2 d-flex justify-content-between align-items-center'>
        <div className='d-flex align-items-center align-self-start'>
          <Button
            variant='outline-primary'
            className='me-2 border-0'
            onClick={toggleSelectedData}
          >
            <AiOutlineArrowLeft size={20} />
          </Button>
          <h2 className='pt-2' style={{ color: 'var(--primaryColor)' }}>
            Driver's Log
          </h2>
        </div>

        <div className='d-flex flex-column flex-sm-row align-self-end'>
          <SplitButton
            align={'end'}
            onSelect={handleSelectStatus}
            variant={conditionalStyling('outline')}
            className={`${conditionalStyling('border')}`}
            disabled={isLoading}
            title={
              selectedStatus
                ? `${
                    selectedStatus === 'rejected'
                      ? `Reject`
                      : selectedStatus === 'pending'
                      ? `Pending`
                      : 'Delete'
                  }`
                : 'Status'
            }
            onClick={() => handleLogStatusChange(selectedStatus)}
          >
            {data.status !== 'pending' && (
              <Dropdown.Item eventKey='pending'>Move to Pending</Dropdown.Item>
            )}
            {data.status !== 'rejected' && (
              <Dropdown.Item eventKey='rejected'>
                Move to Rejected
              </Dropdown.Item>
            )}
            <Dropdown.Item eventKey='delete'>Delete Log</Dropdown.Item>
          </SplitButton>

          {data.status !== 'rejected' && (
            <span className='ms-sm-1 mt-1 mt-sm-0'>
              <Button
                variant='outline-success'
                style={{ border: '1.5px solid' }}
                onClick={() =>
                  data.status === 'approved'
                    ? toggleShowAdminLog()
                    : setShowManageAdminLog(true)
                }
              >
                {data.status === 'approved'
                  ? 'Show Admin Log'
                  : 'Make Admin Log'}
              </Button>
            </span>
          )}
        </div>
      </div>
    </div>
  );
};

export default DriverLogHeader;

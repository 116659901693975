import React, { useContext, useEffect, useState } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import { useUser } from "./UserContext";

const DataContext = React.createContext();

export const useData = () => {
  return useContext(DataContext);
};

const DataProvider = ({ children }) => {
  const { user } = useUser();

  const [isFetchingData, setIsFetchingData] = useState(false);
  const [drivers, setDrivers] = useState([]);
  const [trucks, setTrucks] = useState([]);
  const [driversLogs, setDriversLogs] = useState([]);
  const [adminLogs, setAdminLogs] = useState([]);

  const toastDetails = {
    position: "bottom-left",
    autoClose: 2000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "light",
  };

  useEffect(() => {
    console.log(adminLogs);
  }, [adminLogs]);

  // Links for different axios calls

  // const mainLink = "http://localhost:8000/api/";
  const mainLink = "https://api.bymeer.com/api/";

  const driversLink = mainLink + "driver";

  const trucksLink = mainLink + "truck";

  const driverLogLink = mainLink + "driver-log";

  const adminLogLink = mainLink + "admin-log";

  // Date processing related

  const monthNames = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];

  const ProcessDate = (dateToBeProcessed) => {
    if (dateToBeProcessed !== undefined) {
      const date = new Date(dateToBeProcessed);

      const day = date.getUTCDate();
      const month = monthNames[date.getUTCMonth()];
      const year = date.getUTCFullYear();

      const formattedDate = `${day} ${month} ${year}`;

      return formattedDate;
    } else return undefined;
  };

  //---------------------------Initially fetching all data-----------------------------------------

  useEffect(() => {
    if (user !== null) {
      setIsFetchingData(true);
      setTimeout(() => {
        getDrivers();
        getTrucks();
        getDriversLogs();
        getAdminLogs();
      }, 500);
    }
  }, [user]);

  //---------------------------Drivers related----------------------------------------------------

  const getDrivers = async () => {
    axios
      .get(`${driversLink}/all-drivers`, {
        headers: {
          Authorization: `bearer ${user.token}`,
        },
      })
      .then((response) => {
        setDrivers(response.data);
      })
      .catch((error) => {
        console.error("Error fetching drivers:", error);
      });
  };

  const postDriver = async (data) => {
    try {
      await axios.post(`${driversLink}`, data, {
        headers: {
          Authorization: `bearer ${user.token}`,
        },
      });

      toast.success("Successfully created driver!", toastDetails);
      getDrivers();
    } catch (error) {
      console.error("Error creating driver:", error);
      if (
        error.response &&
        error.response.data &&
        error.response.data.message
      ) {
        toast.error(error.response.data.message, toastDetails);
      } else {
        toast.error(
          "An error occurred while creating the driver",
          toastDetails
        );
      }
    } finally {
      return true;
    }
  };

  const deleteDriver = async (id) => {
    try {
      await axios.delete(`${driversLink}/${id}`, {
        headers: {
          Authorization: `bearer ${user.token}`,
        },
      });

      toast.success("Successfully deleted driver!", toastDetails);
      getDrivers();
    } catch (error) {
      console.error("Error deleting driver:", error);
      if (
        error.response &&
        error.response.data &&
        error.response.data.message
      ) {
        toast.error(error.response.data.message, toastDetails);
      } else {
        toast.error(
          "An error occurred while deleting the driver",
          toastDetails
        );
      }
    } finally {
      return true;
    }
  };

  const updateDriver = async (data, id) => {
    try {
      const response = await axios.put(
        `${driversLink}/update-admin/${id}`,
        data.isPasswordUpdated
          ? data
          : {
              ...data,
              password: null,
            },
        {
          headers: {
            Authorization: `bearer ${user.token}`,
          },
        }
      );

      toast.success("Successfully updated driver!", toastDetails);
      getDrivers();
    } catch (error) {
      console.error("Error updating driver:", error);
      toast.error("An error occurred while updating the driver", toastDetails);
    } finally {
      return true;
    }
  };

  //---------------------------Trucks related----------------------------------------------------

  const getTrucks = async () => {
    axios
      .get(`${trucksLink}`, {
        headers: {
          Authorization: `bearer ${user.token}`,
        },
      })
      .then((response) => {
        setTrucks(response.data.trucks);
      })
      .catch((error) => {
        console.error("Error fetching trucks:", error);
      });
  };

  const postTruck = async (data) => {
    try {
      const response = await axios.post(`${trucksLink}`, data, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `bearer ${user.token}`,
        },
      });

      toast.success("Successfully added truck!", toastDetails);
      getTrucks();
    } catch (error) {
      console.error("Error creating truck:", error);
      if (
        error.response &&
        error.response.data &&
        error.response.data.message
      ) {
        toast.error(error.response.data.message, toastDetails);
      } else {
        toast.error("An error occurred while adding the truck", toastDetails);
      }
    } finally {
      return true;
    }
  };

  const deleteTruck = async (id) => {
    try {
      await axios.delete(`${trucksLink}/${id}`, {
        headers: {
          Authorization: `bearer ${user.token}`,
        },
      });

      toast.success("Successfully deleted truck!", toastDetails);
      getTrucks();
    } catch (error) {
      console.error("Error deleting truck:", error);
      if (
        error.response &&
        error.response.data &&
        error.response.data.message
      ) {
        toast.error(error.response.data.message, toastDetails);
      } else {
        toast.error("An error occurred while deleting the truck", toastDetails);
      }
    } finally {
      return true;
    }
  };

  const updateTruck = async (data, id) => {
    try {
      const response = await axios.put(`${trucksLink}/${id}`, data, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `bearer ${user.token}`,
        },
      });

      toast.success("Successfully updated truck!", toastDetails);
      getTrucks();
    } catch (error) {
      console.error("Error updating truck:", error);
      if (
        error.response &&
        error.response.data &&
        error.response.data.message
      ) {
        toast.error(error.response.data.message, toastDetails);
      } else {
        toast.error("An error occurred while updating the truck", toastDetails);
      }
    } finally {
      return true;
    }
  };

  //---------------------------Driver Logs related----------------------------------------------------

  const getDriversLogs = async () => {
    axios
      .get(`${driverLogLink}/all-driver-logs`, {
        headers: {
          Authorization: `bearer ${user.token}`,
        },
      })
      .then((response) => {
        const formattedData = response.data.driverLogs.map((item) => {
          // converting date to better visibility format
          const formattedStartDate = ProcessDate(item.startDate);
          const formattedEndDate = ProcessDate(item.endDate);
          const formattedCreatedAt = ProcessDate(item.createdAt);
          const formattedUpdatedAt = ProcessDate(item.updatedAt);

          return {
            ...item,
            startDate: formattedStartDate,
            endDate: formattedEndDate,
            createdAt: formattedCreatedAt,
            updatedAt: formattedUpdatedAt,
          };
        });
        setDriversLogs(formattedData);
      })
      .catch((error) => {
        console.error("Error fetching drivers logs:", error);
      });
  };

  const deleteDriverLog = async (id) => {
    axios
      .delete(`${driverLogLink}/${id}`, {
        headers: {
          Authorization: `bearer ${user.token}`,
        },
      })
      .then((response) => {
        toast.success("Successfully deleted driver's log!", toastDetails);
        getDriversLogs();
      })
      .catch((error) => {
        console.error("Error deleting driver's log:", error);
        toast.error("Error occured while deleting driver's log.", toastDetails);
      });
  };

  const updateDriverLogStatus = async (data, id) => {
    axios
      .put(`${driverLogLink}/update-status/${id}`, data, {
        headers: {
          Authorization: `bearer ${user.token}`,
        },
      })
      .then((response) => {
        if (data.status !== "approved")
          toast.success(response.data.message, toastDetails);
        getDriversLogs();
        if (data.initialStatus === "approved") getAdminLogs();
      })
      .catch((error) => {
        console.error("Error updating driver's log status:", error);

        toast.error("Error while updating log status", toastDetails);
      });
  };

  // ===============================Expenses=======================

  const updateExpense = async (data, driverLogId, expenseId) => {
    axios
      .put(
        `${driverLogLink}/expense/update-expense/${driverLogId}/${expenseId}`,
        data,
        {
          headers: {
            Authorization: `bearer ${user.token}`,
          },
        }
      )
      .then((response) => {
        toast.success(
          `Successfully ${
            data.isDenied === true ? "rejected" : "accepted"
          } expense!`,
          toastDetails
        );
        getDriversLogs();
      })
      .catch((error) => {
        console.error("Error rejecting expense:", error);
        toast.error("Error occured while rejecting expense!", toastDetails);
      });
  };
  // ===============================Deductions=======================

  const postDeduction = async (data, id) => {
    try {
      const response = await axios.post(
        `${driverLogLink}/deduction/${id}`,
        data,
        {
          headers: {
            Authorization: `bearer ${user.token}`,
          },
        }
      );

      toast.success("Successfully added deduction!", toastDetails);
      getDriversLogs();
      return true;
    } catch (error) {
      console.error("Error creating deduction:", error);
      toast.error(error.response.data.message, toastDetails);
      return false;
    }
  };

  const deleteDeduction = async (driverLogId, deductionId) => {
    try {
      const response = await axios.delete(
        `${driverLogLink}/deduction/${driverLogId}/${deductionId}`,
        {
          headers: {
            Authorization: `bearer ${user.token}`,
          },
        }
      );

      toast.success("Successfully deleted deduction!", toastDetails);
      getDriversLogs();
      return true;
    } catch (error) {
      console.error("Error deleting deduction:", error);
      toast.error("Error occurred while deleting deduction!", toastDetails);
      return false;
    }
  };

  //---------------------------Admin Logs related----------------------------------------------------

  const getAdminLogs = async () => {
    axios
      .get(`${adminLogLink}`, {
        headers: {
          Authorization: `bearer ${user.token}`,
        },
      })
      .then((response) => {
        const formattedData = response.data.adminLogs.map((item) => {
          // converting date to better visibility format
          const formattedUpdatedAt = ProcessDate(item.createdAt);

          return {
            ...item,
            updatedAt: formattedUpdatedAt,
          };
        });
        setAdminLogs(formattedData);
      })
      .catch((error) => {
        console.error("Error fetching admin logs:", error);
      })
      .finally(() => {
        setIsFetchingData(false);
      });
  };

  const postAdminLog = async (data, id) => {
    axios
      .post(`${adminLogLink}/${id}`, data, {
        headers: {
          Authorization: `bearer ${user.token}`,
        },
      })
      .then((response) => {
        toast.success("Successfully created admin log!", toastDetails);
        getDriversLogs();
        getAdminLogs();
      })
      .catch((error) => {
        console.error("Error creating admin log:", error);
        toast.error(error.response.data.error, toastDetails);
      });
  };

  return (
    <DataContext.Provider
      value={{
        toastDetails,
        isFetchingData,
        drivers,
        postDriver,
        deleteDriver,
        updateDriver,
        trucks,
        postTruck,
        deleteTruck,
        updateTruck,
        driversLogs,
        deleteDriverLog,
        updateDriverLogStatus,
        updateExpense,
        postDeduction,
        deleteDeduction,
        adminLogs,
        postAdminLog,
      }}
    >
      {children}
    </DataContext.Provider>
  );
};

export default DataProvider;

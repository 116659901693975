import DriverDisplayModal from "../DriverDisplayModal";
import TruckDisplayModal from "../TruckDisplayModal";
import DisplayModalTemplate from "../../../utils/DisplayModalTemplate/DisplayModalTemplate";

import { FaTruckFast, FaLocationDot, FaFlag } from "react-icons/fa6";

import { useState, useEffect } from "react";
import { useData } from "../../../context/DataContext";
import { useStyle } from "../../../context/StyleContext";

const DriverLogDetails = ({ data, setSelectedData }) => {
  const { drivers, trucks } = useData();
  const { primaryBtn, showTextFieldValueInDisplay } = useStyle();

  const [showDriver, setShowDriver] = useState(false);
  const handleDriverShow = () => setShowDriver(!showDriver);

  const [showTruck, setShowTruck] = useState(false);
  const handleTruckShow = () => setShowTruck(!showTruck);

  const [currentDriver, setCurrentDriver] = useState(null);
  const [currentTruck, setCurrentTruck] = useState(null);

  useEffect(() => {
    const filteredDriver = drivers?.filter(
      (singleDriver) => singleDriver._id === data.driverId
    );
    setCurrentDriver(filteredDriver[0]);

    const filteredTruck = trucks?.filter(
      (singleTruck) => singleTruck.truckPlateNumber === data.truckPlateNumber
    );
    setCurrentTruck(filteredTruck[0]);
  }, [data]);

  useEffect(() => {
    setSelectedData({
      ...data,
      currentDriver: currentDriver,
      currentTruck: currentTruck,
    });
  }, [currentTruck, currentDriver]);

  const locationComponent = (date, icon, location) => {
    return (
      <>
        <p
          style={{
            marginBottom: "5px",
            fontSize: "small",
            color: "grey",
            textTransform: "capitalize",
          }}
        >
          {date}
        </p>
        {icon}
        <h4
          style={{
            marginTop: "5px",
            color: "var(--partitionColor)",
            textTransform: "capitalize",
          }}
        >
          {location}
        </h4>
      </>
    );
  };
  return (
    <>
      {showDriver && (
        <DisplayModalTemplate
          title="Selected Logs Driver"
          data={currentDriver}
          displayDataShow={showDriver}
          toggleDisplayDataShow={handleDriverShow}
          DisplayModalComponent={DriverDisplayModal}
          EditModalComponent={undefined}
        />
      )}
      {showTruck && (
        <DisplayModalTemplate
          title="Selected Logs Truck"
          data={currentTruck}
          displayDataShow={showTruck}
          toggleDisplayDataShow={handleTruckShow}
          DisplayModalComponent={TruckDisplayModal}
          EditModalComponent={undefined}
        />
      )}
      <div
        className={`px-0 px-md-4 col-12 d-flex flex-column flex-sm-row flex-md-column text-center text-sm-start`}
      >
        <div className="me-3 me-md-0 mb-md-3 col-sm-6 col-md-12 d-flex flex-column flex-md-row justify-content-between align-items-md-center ">
          {showTextFieldValueInDisplay("Trip ID", data?.tripId)}

          <div className="d-block d-sm-none">
            {showTextFieldValueInDisplay("Status", data?.status)}
          </div>

          {showTextFieldValueInDisplay("Truck", data?.truckPlateNumber)}

          {currentTruck && (
            <div className={`ms-md-2 d-none d-sm-block`}>
              {primaryBtn("Show Truck", handleTruckShow)}
            </div>
          )}
        </div>

        <div className="col-sm-6 col-md-12 d-flex flex-column flex-md-row justify-content-between align-items-md-center">
          <div className="d-none d-sm-block">
            {showTextFieldValueInDisplay("Status", data?.status)}
          </div>

          {showTextFieldValueInDisplay("Driver", currentDriver?.name)}

          <div className="d-flex justify-content-center justify-content-sm-start">
            {currentDriver && (
              <div className={`ms-md-2 me-2 me-sm-0`}>
                {primaryBtn("Show Driver", handleDriverShow)}
              </div>
            )}

            {currentTruck && (
              <div className={`ms-md-2 d-block d-sm-none`}>
                {primaryBtn("Show Truck", handleTruckShow)}
              </div>
            )}
          </div>
        </div>

        <div className="pt-2 col-sm-6 text-start">
          {showTextFieldValueInDisplay(
            "Solo Trip",
            `${data?.isSolo ? "Yes" : "No"}`
          )}
        </div>
        <div className="d-flex justify-content-between">
          <div className="pt-2 col-sm-6 text-start">
            {showTextFieldValueInDisplay(
              "Created at",

              `${data.createdAt}`
            )}
          </div>
          <div className="pt-2 col-sm-6 text-end">
            {showTextFieldValueInDisplay(
              "Updated at",

              `${data.updatedAt}`
            )}
          </div>
        </div>
      </div>

      <div className="mt-5 mb-3 pe-3 col-12 d-flex justify-content-around align-items-center">
        <div>
          {locationComponent(
            data?.startDate,
            <FaLocationDot size={30} />,
            data?.startLocation
          )}
        </div>

        <div className="pb-4 pb-sm-0">
          {locationComponent("", <FaTruckFast size={30} />, "")}
        </div>

        <div>
          {locationComponent(
            data?.endDate,
            <FaFlag size={30} />,
            data?.endLocation
          )}
        </div>
      </div>
    </>
  );
};

export default DriverLogDetails;

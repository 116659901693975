import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import DataProvider from "./context/DataContext";
import UserProvider from "./context/UserContext";
import StyleProvider from "./context/StyleContext";
import { BrowserRouter } from "react-router-dom";
import "./index.css";
import "bootstrap/dist/css/bootstrap.min.css";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <UserProvider>
    <DataProvider>
      <StyleProvider>
        <BrowserRouter>
          <App />
        </BrowserRouter>
      </StyleProvider>
    </DataProvider>
  </UserProvider>
);
reportWebVitals();

import React from "react";
import Button from "react-bootstrap/esm/Button";
import { useStyle } from "../context/StyleContext";

const ConfirmDialog = ({ message, onConfirm, onCancel, reference }) => {
  const { primaryBtn } = useStyle();
  return (
    <div className="confirm-dialog bg-transparent">
      <p>{message}</p>

      <Button variant="outline-danger" onClick={onConfirm} className="me-2">
        YES
      </Button>
      {primaryBtn("NO", onCancel, false, "Don't delete")}
    </div>
  );
};

export default ConfirmDialog;

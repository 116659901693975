import { Table } from "react-bootstrap";

const LogCalculationTable = ({
  log,
  isAdminLog = false,
  calledInAdminLog = false,
}) => {
  const mileageReimbursment =
    (isAdminLog ? log?.companyRate : log?.rate) * log?.miles;

  const dispatchedAmount = mileageReimbursment * (log?.dispatcher / 100);

  const totalDeductionCost = log?.deductions?.reduce(
    (accumulator, currentDeduction) => accumulator + currentDeduction.cost,
    0
  );

  const totalExpenseCost =
    log?.expenses?.reduce((accumulator, currentExpense) => {
      if (!currentExpense.isDenied) {
        return accumulator + currentExpense.cost;
      }
      return accumulator;
    }, 0) || 0;

  return (
    <div className="pe-2 mb-3">
      <div className="pb-2 d-flex justify-content-between">
        <h4 className="text-start" style={{ color: "var(--primaryColor)" }}>
          {isAdminLog ? "Admin" : "Driver"} Log:
        </h4>
      </div>
      <Table responsive bordered hover className="px-5" variant="light">
        <thead>
          <tr>
            <th className="bg-dark-subtle">Title</th>
            <th className="bg-dark-subtle">Amount</th>
          </tr>
        </thead>

        <tbody>
          <tr>
            <td>Miles</td>
            <td>{log?.miles}</td>
          </tr>

          <tr>
            <td>{isAdminLog ? "Company Rate" : "Driver's Rate"}</td>
            <td>${isAdminLog ? log?.companyRate : log?.rate}</td>
          </tr>

          {isAdminLog && (
            <>
              <tr>
                <td>Company's Pay</td>
                <td>${mileageReimbursment}</td>
              </tr>

              <tr>
                <td>Dispatch</td>
                <td>${dispatchedAmount.toFixed(2)}</td>
              </tr>

              <tr>
                <td>Driver's Pay</td>
                <td>${log?.driverLogTotalAmount}</td>
              </tr>

              {log?.dieselExpenses?.length !== 0 && (
                <>
                  <tr>
                    <td colSpan="3" className="fw-bold bg-dark-subtle">
                      Diesel Expenses
                    </td>
                  </tr>

                  {log?.dieselExpenses?.map((expense, index) => {
                    // if (expense !== 0) {
                    return (
                      <tr key={index}>
                        <td>{`Diesel Expense ${index + 1}`}</td>
                        <td>${expense}</td>
                      </tr>
                    );
                    // }
                  })}

                  {log?.dieselExpenses?.length > 1 && (
                    <tr>
                      <td className="fw-semibold">Total Diesel Expense</td>
                      <td
                        className={`${
                          !calledInAdminLog && "text-danger"
                        } fw-semibold`}
                      >
                        ${log?.totalDieselExpense}
                      </td>
                    </tr>
                  )}
                </>
              )}
            </>
          )}

          {!isAdminLog && (
            <>
              <tr>
                <td>
                  <h6>{"Mileage Reimbursement"}</h6>
                </td>

                <td
                  className={`${
                    !calledInAdminLog && "text-success"
                  } fw-semibold`}
                >
                  ${mileageReimbursment}
                </td>
              </tr>
              {log?.deductions?.length !== 0 && (
                <>
                  <tr>
                    <td colSpan="3" className="fw-bold bg-dark-subtle">
                      Deductions
                    </td>
                  </tr>

                  {log?.deductions.map((deduction, index) => {
                    return (
                      <tr key={index}>
                        <td>{deduction.title}</td>
                        <td>${deduction.cost}</td>
                      </tr>
                    );
                  })}

                  <tr>
                    <td className="fw-semibold">Total Deductions</td>
                    <td
                      className={`${
                        !calledInAdminLog && "text-danger"
                      } fw-semibold`}
                    >
                      ${totalDeductionCost}
                    </td>
                  </tr>
                </>
              )}
              {log?.expenses?.length !== 0 && (
                <>
                  <tr>
                    <td colSpan="3" className="fw-bold bg-dark-subtle">
                      Out of Pocket Expenses
                    </td>
                  </tr>
                  {log?.expenses.map((expense, index) => {
                    if (!expense.isDenied)
                      return (
                        <tr key={index}>
                          <td>{expense.title}</td>
                          <td>${expense.cost}</td>
                        </tr>
                      );
                  })}
                  <tr>
                    <td className="fw-semibold">
                      Total Out of Pocket Expenses
                    </td>
                    <td
                      className={`${
                        !calledInAdminLog && "text-success"
                      } fw-semibold`}
                    >
                      ${totalExpenseCost}
                    </td>
                  </tr>
                </>
              )}{" "}
            </>
          )}

          <tr>
            <td colSpan="3" className="fw-bold bg-dark-subtle">
              {isAdminLog
                ? log?.netIncome < 0
                  ? "Net Loss"
                  : "Net Income"
                : "Total Trip Cost"}
            </td>
          </tr>

          <tr>
            <td className="fw-semibold">
              {isAdminLog
                ? log?.netIncome < 0
                  ? "Net Loss"
                  : "Net Income"
                : "Total Amount"}
            </td>
            <td
              className={`fw-semibold ${
                isAdminLog
                  ? log?.netIncome < 0
                    ? "text-danger"
                    : "text-success"
                  : calledInAdminLog && "text-danger"
              }`}
            >
              $
              {isAdminLog
                ? log?.netIncome > 0
                  ? log?.netIncome
                  : String(log?.netIncome).slice(
                      1,
                      String(log?.netIncome).indexOf(".") + 3
                    )
                : log?.totalAmount}
            </td>
          </tr>
        </tbody>
      </Table>
    </div>
  );
};

export default LogCalculationTable;

import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import { useStyle } from '../../context/StyleContext';
import 'animate.css';

const ManipulateDataTemplate = ({
  title,
  show,
  handleShow,
  formik,
  isLoading,
  children,
  navigate,
}) => {
  const { modalHeaderStyle, submitBtn } = useStyle();

  const modifiedHandleShow = () => {
    handleShow();
    setTimeout(() => {
      formik.resetForm();
    }, 300);
    if (navigate !== undefined) navigate();
  };
  return (
    <Modal
      show={show}
      onHide={modifiedHandleShow}
      aria-labelledby="contained-modal-title-vcenter"
      centered
      backdrop="static"
      keyboard={false}
      className=".bg-black .bg-gradient "
      style={{ backdropFilter: 'blur(1.5px)' }}
    >
      <Modal.Header closeButton style={modalHeaderStyle}>
        <Modal.Title className="fw-bolder text-secondary-emphasis">
          <span style={{ color: 'var(--primaryColor)' }}>{title}</span>
        </Modal.Title>
      </Modal.Header>
      <Form onSubmit={formik.handleSubmit} style={{ border: '0px' }}>
        <Modal.Body
          className="overflow-auto"
          style={{
            borderBottom: 'var(--primaryColor) solid 1px',
            maxHeight: '60vh',
          }}
        >
          {children}
        </Modal.Body>

        <Modal.Footer className="border-top-0">
          {submitBtn(isLoading)}
        </Modal.Footer>
      </Form>
    </Modal>
  );
};

export default ManipulateDataTemplate;

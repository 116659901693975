import DriversLogsTable from '../../components/Tables/Logs/DriversLogsTable';
import DriversLogsDisplay from '../../components/DisplayModals/Logs/DriversLogsDisplay';

import { FaSearch } from 'react-icons/fa';
import { useState, useEffect } from 'react';
import { useData } from '../../context/DataContext';
import { useStyle } from '../../context/StyleContext';

const DriversLogs = () => {
  const {
    partition,
    showTitle,
    textSearch,
    loader,
    searchDivStyle,
    searchInputStyle,
  } = useStyle();

  const { driversLogs, isFetchingData } = useData();

  // Selection of data and displaying the modal for data
  const [selectedData, setSelectedData] = useState(null);
  const toggleSelectedData = () => setSelectedData(null);

  //Related to handling searches

  const [searchField, setSearchField] = useState('');
  const handleSearchFieldChange = (e) => setSearchField(e.target.value);

  const [searchStatus, setSearchStatus] = useState('pending');
  const handleSearchStatusChange = (e) => setSearchStatus(e.target.value);

  const [filteredDriversLogs, setFilteredDriversLogs] = useState(driversLogs);

  useEffect(() => {
    const filterData = () => {
      if (driversLogs !== undefined) {
        const filtered = driversLogs.filter((singleLog) => {
          const { tripId, truckPlateNumber, status } = singleLog;
          const lowerCaseTripId = tripId ? tripId.toLowerCase() : '';
          const lowerCaseTruckPlateNumber = truckPlateNumber
            ? truckPlateNumber.toLowerCase()
            : '';

          return (
            (searchField === '' ||
              (lowerCaseTripId &&
                lowerCaseTripId.includes(searchField.toLowerCase())) ||
              (lowerCaseTruckPlateNumber &&
                lowerCaseTruckPlateNumber.includes(
                  searchField.toLowerCase()
                ))) &&
            searchStatus === status
          );
        });
        setFilteredDriversLogs(filtered);
      }
    };

    filterData();
  }, [driversLogs, searchField, searchStatus, selectedData]);

  return (
    <>
      {isFetchingData ? (
        // Loading if the data isn't fetched yet
        loader
      ) : selectedData !== null ? (
        <>
          <DriversLogsDisplay
            data={selectedData}
            toggleSelectedData={toggleSelectedData}
            setSelectedData={setSelectedData}
          />
        </>
      ) : (
        <div className='my-3 mx-4 mx-md-2 mx-lg-2'>
          {showTitle("Drivers' Logs")}

          {partition}

          <div className='w-100 pe-4 d-flex flex-md-row flex-column justify-content-between'>
            <div className='col-lg-4 col-md-6 mb-md-0 mb-2 '>
              {textSearch(
                <FaSearch size={18} className='me-2 mt-1' />,
                searchField,
                'Trip id or truck plate number',
                handleSearchFieldChange
              )}
            </div>

            <div className='mx-md-1'>
              <div style={searchDivStyle} className='px-3'>
                <select
                  className='w-100'
                  style={searchInputStyle}
                  value={searchStatus}
                  onChange={handleSearchStatusChange}
                >
                  <option value='pending'>Pending</option>
                  <option value='approved'>Approved</option>
                  <option value='rejected'>Rejected</option>
                </select>
              </div>
            </div>
          </div>

          {partition}

          {filteredDriversLogs.length === 0 ? (
            <h4 style={{ color: 'var(--partitionColor)', opacity: '70%' }}>
              No Drivers' Logs to display...
            </h4>
          ) : (
            <>
              <DriversLogsTable
                tableData={filteredDriversLogs}
                handleClick={(data) => setSelectedData(data)}
              />
            </>
          )}
        </div>
      )}
    </>
  );
};

export default DriversLogs;

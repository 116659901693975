import AdminLogDisplay from './AdminLogDisplay';
import DriverLogHeader from './DriverLogHeader';
import DriverLogDetails from './DriverLogDetails';
import ExpenseDeductionDisplay from './ExpenseDeductionDisplay';
import LogCalculationTable from '../../Tables/Logs/LogCalculationTable';

import { useData } from '../../../context/DataContext';
import { useStyle } from '../../../context/StyleContext';
import { useScreenSize } from '../../../utils/ScreenSizeDetector';
import { useState, useEffect } from 'react';

const DriversLogsDisplay = ({ data, toggleSelectedData, setSelectedData }) => {
  const { adminLogs, updateExpense, postDeduction, deleteDeduction } =
    useData();
  const { partition } = useStyle();
  const isMobile = useScreenSize();

  const [showAdminLog, setShowAdminLog] = useState(false);
  const toggleShowAdminLog = () => setShowAdminLog(!showAdminLog);

  const [currentAdminLog, setCurrentAdminLog] = useState(null);

  useEffect(() => {
    const filteredAdminLogs = adminLogs?.filter(
      (singleLog) => singleLog.driverProvidedLog._id === data._id
    );
    if (filteredAdminLogs.length != 0) setCurrentAdminLog(filteredAdminLogs[0]);
    else setCurrentAdminLog(filteredAdminLogs[0]);
  }, [data]);

  // Expense rejection or acceptance related code
  const toggleExpenseStatus = async (status, expenseId) => {
    await updateExpense({ isDenied: status }, data._id, expenseId);
    const updatedExpenses = data.expenses.map((expense) => {
      return expense._id === expenseId
        ? { ...expense, isDenied: status }
        : expense;
    });
    data.expenses = updatedExpenses;
    setSelectedData(data);
  };

  // Deduction related operations
  const deductionOperations = {
    addNewDeduction: async (values) => {
      const pushDeduction = await postDeduction(values, data._id);

      if (pushDeduction) {
        const updatedDeductions = [...data.deductions, { ...values }];

        data.deductions = updatedDeductions;
        data.totalAmount -= values.cost;
        setSelectedData(data);
      }
    },
    removeExistingDeduction: async (deductionId, deductionCost) => {
      const extractDeduction = await deleteDeduction(data._id, deductionId);
      if (extractDeduction) {
        const updatedDeductions = data.deductions.filter(
          (deduction) => deduction._id !== deductionId
        );

        data.deductions = updatedDeductions;
        data.totalAmount += deductionCost;
        setSelectedData(data);
      }
    },
  };

  return (
    <div className='my-3 mx-2' style={{ maxWidth: '98vw' }}>
      {data &&
        (showAdminLog ? (
          <AdminLogDisplay
            adminLog={currentAdminLog}
            driverLog={data}
            toggleShowAdminLog={toggleShowAdminLog}
          />
        ) : (
          <>
            <DriverLogHeader
              data={data}
              toggleSelectedData={toggleSelectedData}
              toggleShowAdminLog={toggleShowAdminLog}
            />
            {partition}

            <div
              style={{ maxHeight: !isMobile ? '85vh' : '75vh' }}
              className='mx-2 overflow-y-auto overflow-x-hidden'
            >
              <DriverLogDetails data={data} setSelectedData={setSelectedData} />
              <div className='px-3'>{partition}</div>

              <ExpenseDeductionDisplay
                data={data.expenses}
                isExpense={true}
                handleClick={toggleExpenseStatus}
                currentAdminLog={currentAdminLog}
              />

              <ExpenseDeductionDisplay
                data={data.deductions}
                handleClick={deductionOperations}
                currentAdminLog={currentAdminLog}
              />
              <div className='px-3 pt-1 pb-3'>{partition}</div>

              <LogCalculationTable log={data} />
            </div>
          </>
        ))}
    </div>
  );
};

export default DriversLogsDisplay;
